/**
 * URL utility functions for MossBeachLife
 * These functions help with creating consistent URLs across the application
 */

/**
 * Creates an absolute URL with the current origin
 * @param {string} path - The path to append to the origin (should not include leading slash)
 * @returns {string} - The complete absolute URL
 */
export function getAbsoluteUrl(path) {
  // Remove leading slash if present
  const cleanPath = path.startsWith('/') ? path.substring(1) : path;
  // Use window.location.origin to get the current origin (http://localhost:8080 in development, https://mossbeachlife.com in production)
  // return `${window.location.origin}/${cleanPath}`;
  return `https://mossbeachlife.com/${cleanPath}`
}

/**
 * Creates a social share URL for a product group
 * @param {string} type - The product type
 * @param {string} group - The product group name
 * @returns {string} - The complete URL for social sharing
 */
export function getProductShareUrl(type, group) {
  if (!type || !group) return '';
  
  // For static HTML share pages
  const cleanGroup = group.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_-]/g, '');
  return getAbsoluteUrl(`share/store/${encodeURIComponent(type)}/${cleanGroup}.html`);
}

/**
 * Creates a direct URL for a product group (for user navigation)
 * @param {string} type - The product type
 * @param {string} group - The product group name
 * @returns {string} - The complete URL for direct linking
 */
export function getProductDirectUrl(type, group) {
  if (!type || !group) return '';
  
  return getAbsoluteUrl(`store/${encodeURIComponent(type)}/${encodeURIComponent(group)}`);
}

/**
 * Creates a social share URL for a print
 * @param {string} category - The print category
 * @param {string} printId - The print ID
 * @returns {string} - The complete URL for social sharing
 */
export function getPrintShareUrl(category, printId) {
  if (!category || !printId) return '';
  
  // For static HTML share pages
  const cleanId = printId.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_-]/g, '');
  return getAbsoluteUrl(`share/prints/${encodeURIComponent(category)}/${cleanId}.html`);
}

/**
 * Creates a direct URL for a print (for user navigation)
 * @param {string} category - The print category
 * @param {string} printId - The print ID
 * @returns {string} - The complete URL for direct linking
 */
export function getPrintDirectUrl(category, printId) {
  if (!category || !printId) return '';
  
  return getAbsoluteUrl(`prints/${encodeURIComponent(category)}/${encodeURIComponent(printId)}`);
}

/**
 * Creates a URL for the site's home page
 * @returns {string} - The complete URL for the home page
 */
export function getHomeUrl() {
  return window.location.origin;
}

/**
 * Converts a relative image path to an absolute URL
 * @param {string} imagePath - The relative image path
 * @returns {string} - The complete absolute URL for the image
 */
export function getImageUrl(imagePath) {
  if (!imagePath) return '';
  
  // If already absolute, return as-is
  if (imagePath.startsWith('http://') || imagePath.startsWith('https://')) {
    return imagePath;
  }
  
  // Remove leading slash if present
  const cleanPath = imagePath.startsWith('/') ? imagePath.substring(1) : imagePath;
  return getAbsoluteUrl(cleanPath);
} 