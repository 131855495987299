<template>
  <div class="print-detail">
    <!-- Breadcrumb Navigation -->
    <div class="breadcrumb">
      <router-link :to="{ name: 'prints-v2' }">All Categories</router-link>
      <span> > </span>
      <router-link 
        :to="{ name: 'prints-v2-category', params: { category: $route.params.category }}"
      >
        {{ formatCategoryName($route.params.category) }}
      </router-link>
      <span> > </span>
      <span>{{ photo?.title || 'Loading...' }}</span>
    </div>

    <div v-if="loading" class="loading">
      Loading photo details...
    </div>
    <div v-else-if="error" class="error">
      {{ error }}
    </div>
    <div v-else-if="!photo" class="error">
      Photo not found.
    </div>
    <div v-else>
      <h1 class="photo-title">{{ photo.title }}</h1>
      <div class="content">
        <!-- Photo Display -->
        <div class="photo-section">
          <div class="main-image-container" @contextmenu.prevent>
            <img
              :src="photo.imagePathLarge?.url || photo.imagePathSmall?.url" 
              :alt="photo.title"
              class="main-photo"
              @click="showImageModal = true"
              draggable="false"
            />
            <div class="image-protection"></div>
          </div>

          <!-- Social Sharing -->
          <social-sharing 
            v-if="enhanced"
            :print-category="$route.params.category"
            :print-id="$route.params.photoId"
            :print-title="photo.title"
            :image-url="photo.imagePathSmall.url"
            title="Share this Photo"
          />

          <!-- Image Modal -->
          <div v-if="showImageModal" class="image-modal" @click="showImageModal = false">
            <div class="modal-content" @contextmenu.prevent>
              <button class="close-button" @click.stop="showImageModal = false">&times;</button>
              <div class="image-container">
                <img 
                  :src="photo.imagePathLarge?.url" 
                  :alt="photo.title"
                  class="modal-image"
                  @contextmenu.prevent
                  draggable="false"
                />
                <div class="image-protection"></div>
              </div>
            </div>
          </div>

          <!-- Print Formats -->
          <div v-if="photo.printFormats?.length" class="formats-section">
            <h2 class="section-title">Available Formats</h2>
            <p v-if="hasRecommendedOptions" class="format-legend">
              <span class="recommended-star">★</span> = Recommended option for best quality and value
            </p>
            <div class="format-list">
              <div 
                v-for="format in photo.printFormats" 
                :key="format.format"
                class="format-card"
              >
                <div class="format-header" @click="toggleFormat(format.format)">
                  <div class="format-title">
                    <h3>{{ formatFormat(format.format) }}</h3>
                    <span class="toggle-icon">{{ isFormatOpen(format.format) ? '▼' : '▶' }}</span>
                  </div>
                </div>
                <div v-if="isFormatOpen(format.format)" class="format-content">
                  <p class="format-description">{{ getFormatDescription(format.format) }}</p>
                  <div class="finish-descriptions">
                    <button 
                      class="toggle-descriptions" 
                      @click.stop="toggleFinishDescriptions(format.format)"
                    >
                      {{ isFinishDescriptionOpen(format.format) ? 'Hide' : 'Show' }} Finish Details
                      <span class="toggle-icon">{{ isFinishDescriptionOpen(format.format) ? '▼' : '▶' }}</span>
                    </button>
                    <div v-if="isFinishDescriptionOpen(format.format)" class="finish-details">
                      <div v-for="finish in getAllFinishes(format)" :key="finish" class="finish-detail">
                        <span class="finish-detail-name">{{ formatFinish(finish) }}:</span>
                        <span class="finish-detail-description">{{ getFinishDescription(format, finish) }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="sizes">
                    <div 
                      v-for="size in format.getSizes()" 
                      :key="size"
                      class="size-item"
                    >
                      <div class="size-header">{{ size }}</div>
                      <div class="finishes">
                        <div
                          v-for="finish in format.getFinishes(size)"
                          :key="finish"
                          class="finish-option"
                          :class="{ 
                            selected: isSelected(format, size, finish),
                            recommended: format.getProduct(size, finish)?.recommended 
                          }"
                          @click.stop="selectOption(format, size, finish)"
                        >
                          <div class="finish-name">{{ formatFinish(finish) }}</div>
                          <div class="finish-price">
                            {{ formatPrice(format.getProduct(size, finish)?.unitPrice) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Add to Cart Section -->
            <div v-if="selectedProduct" class="cart-section">
              <div class="selected-summary">
                <h3>Selected Option</h3>
                <p>{{ formatFormat(selectedProduct.format) }} - {{ selectedProduct.size }} - {{ formatFinish(selectedProduct.finish) }}</p>
                <p class="price">{{ formatPrice(selectedProduct.product?.unitPrice) }} each</p>
              </div>
              <div class="quantity-selector">
                <button 
                  class="quantity-btn" 
                  @click="quantity > 1 ? quantity-- : null"
                  :disabled="quantity <= 1"
                >-</button>
                <span class="quantity">{{ quantity }}</span>
                <button 
                  class="quantity-btn" 
                  @click="quantity++"
                >+</button>
              </div>
              <div class="total-section">
                <span class="total-label">Total:</span>
                <span class="total-price">{{ totalPrice }}</span>
              </div>
              <button 
                class="add-to-cart-btn" 
                @click="addToCart"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>

        <!-- Photo Info -->
        <div class="info-section">
          <p v-if="photo.description" class="description">{{ photo.description }}</p>

          <!-- Related Products -->
          <div v-if="photo.printProducts?.length" class="related-section">
            <div class="section-header" @click="toggleSection('related')">
              <h3 class="section-title">Featured in Products</h3>
              <span class="toggle-icon">{{ isSectionOpen('related') ? '▼' : '▶' }}</span>
            </div>
            <div v-if="isSectionOpen('related')" class="section-content">
              <div class="related-list">
                <div 
                  v-for="product in photo.printProducts" 
                  :key="product.stripeProductId"
                  class="related-item"
                >
                  <router-link 
                    v-if="!isExternalProductUrl(product.productUrl) && (product.productUrl || generateProductUrl(product.type, product.group))" 
                    :to="convertToRouteLocation(product.productUrl || generateProductUrl(product.type, product.group))"
                    class="related-link"
                  >
                    {{ formatProductType(product.type) }} - {{ product.group }}
                  </router-link>
                  <a
                    v-else-if="product.productUrl"
                    :href="product.productUrl"
                    target="_blank"
                    class="external-link"
                  >
                    {{ formatProductType(product.type) }} - {{ product.group }}
                    <span class="external-indicator">↗</span>
                  </a>
                  <span v-else class="no-link">
                    {{ formatProductType(product.type) }} - {{ product.group }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Print Locations -->
          <div v-if="photo.printLocations?.length" class="locations-section">
            <div class="section-header" @click="toggleSection('locations')">
              <h3 class="section-title">Where to See This Print</h3>
              <span class="toggle-icon">{{ isSectionOpen('locations') ? '▼' : '▶' }}</span>
            </div>
            <div v-if="isSectionOpen('locations')" class="section-content">
              <div class="location-list">
                <div
                    v-for="location in photo.printLocations.filter(l => l.isActive)"
                    :key="location.id"
                    class="location-item"
                >
                  <div class="location-header" @click="toggleLocationCollapse(location.id)">
                    <span class="location-title">{{location.title}}</span>
                    <span class="toggle-icon">{{ isLocationCollapsed(location.id) ?  '▶': '▼' }}</span>
                  </div>
                  <div v-show="!isLocationCollapsed(location.id)">
                    <print-location :location="location" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { CartItem } from '@/models/CartItem'
import Swal from 'sweetalert2'
import {PRINT_FINISH_DESCRIPTIONS, PRINT_FORMAT_DESCRIPTIONS} from '@/models/PrintV2'
import PrintLocation from '@/components/printsv2/PrintLocation.vue'
import SocialSharing from '@/components/social/SocialSharing.vue'
import errorReporting from "@/errorReporting";

export default {
  name: 'PrintDetailV2',
  components: {
    PrintLocation,
    SocialSharing
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const loading = ref(true)
    const error = ref(null)
    const photo = ref(null)
    const selectedProduct = ref(null)
    const quantity = ref(1)
    const showImageModal = ref(false)

    const openFinishDescriptions = ref(new Set())
    const openFormats = ref(new Set())
    const isDesktop = ref(window.innerWidth >= 1025)
    const openSections = ref(new Set(window.innerWidth >= 1025 ? ['related', 'locations'] : []))
    const collapsedLocations = ref(new Set())

    const enhanced = computed(() => store.getters["metadata/isEnhancedMode"]);

    const hasRecommendedOptions = computed(() => {
      if (!photo.value?.printFormats) return false;
      return photo.value.printFormats.some(format => 
        format.getSizes().some(size => 
          format.getFinishes(size).some(finish => 
            format.getProduct(size, finish)?.recommended
          )
        )
      );
    });

    // Add window resize handler
    const handleResize = () => {
      isDesktop.value = window.innerWidth >= 1025;
      // Update sections visibility based on screen size
      if (isDesktop.value) {
        openSections.value.add('related');
        openSections.value.add('locations');
      } else {
        openSections.value.delete('related');
        openSections.value.delete('locations');
      }
    };

    const loadPhoto = async () => {
      loading.value = true
      error.value = null
      
      try {
        // Check if categories are loaded
        if (!store.state.printsv2.categories?.length) {
          throw new Error('Categories not loaded')
        }

        const category = store.state.printsv2.categories.find(
          c => c.category === route.params.category
        )
        
        if (!category) {
          throw new Error('Category not found')
        }

        const foundPhoto = category.photos?.find(
          p => p.id.toUpperCase() === route.params.photoId.toUpperCase()
        )

        if (!foundPhoto) {
          throw new Error('Photo not found')
        }

        photo.value = foundPhoto
        
        // Update page title and meta with the actual photo title
        if (foundPhoto.title) {
          const categoryTitle = formatCategoryName(route.params.category);
          const description = `${foundPhoto.title} - A beautiful photo from our ${categoryTitle} collection at Moss Beach Life.`;
          
          // Create image metadata object
          const imageMetadata = foundPhoto.imagePathSmall?.url 
            ? {
                url: foundPhoto.imagePathSmall.url,
                alt: `${foundPhoto.title} - ${categoryTitle}`
              }
            : null;
          
          // Create Twitter Card specific meta tags
          const metaTags = [
            // Basic meta tags
            { 
              name: 'description', 
              content: description
            },
            
            // Open Graph tags
            { 
              property: 'og:title', 
              content: `${foundPhoto.title} - ${categoryTitle} | Moss Beach Life`
            },
            { 
              property: 'og:description', 
              content: description
            },
            { 
              property: 'og:url', 
              content: window.location.href 
            },
            { 
              property: 'og:type', 
              content: 'website' 
            },
            
            // Twitter Card tags
            { 
              name: 'twitter:card', 
              content: 'summary_large_image' 
            },
            { 
              name: 'twitter:title', 
              content: `${foundPhoto.title} - ${categoryTitle} | Moss Beach Life`
            },
            { 
              name: 'twitter:description', 
              content: description
            }
          ];
          
          // Add image meta tags if an image is provided
          if (imageMetadata?.url) {
            metaTags.push(
              { property: 'og:image', content: imageMetadata.url },
              { name: 'twitter:image', content: imageMetadata.url }
            );
          }
          
          // Use the store action to update page metadata including the image
          store.dispatch('metadata/updateMetaInfo', { 
            title: {
              pageType: 'prints',
              printCategory: route.params.category,
              printTitle: foundPhoto.title
            }, 
            description,
            image: imageMetadata,
            metaTags: metaTags
          });
        }
      } catch (error) {
        console.error('loadPhoto error', error)
        errorReporting.reportVueError(error, this, 'PrintDetailV2.loadPhoto')
        error.value = error.message
      } finally {
        loading.value = false
      }
    }

    const formatCategoryName = (category) => {
      if (!category) return '';
      return category
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }

    const selectOption = (format, size, finish) => {
      const product = format.getProduct(size, finish)
      if (product) {
        selectedProduct.value = {
          format: format.format,
          size,
          finish,
          product
        }
      }
    }

    const isSelected = (format, size, finish) => {
      return selectedProduct.value &&
        selectedProduct.value.format === format.format &&
        selectedProduct.value.size === size &&
        selectedProduct.value.finish === finish
    }

    const addToCart = async () => {
      if (!selectedProduct.value?.product) return
      
      try {
        // Create URL with selected options as parameters
        const baseUrl = window.location.pathname
        const params = new URLSearchParams({
          format: encodeURIComponent(selectedProduct.value.format),
          size: encodeURIComponent(selectedProduct.value.size),
          finish: encodeURIComponent(selectedProduct.value.finish)
        })
        const productUrl = `${baseUrl}?${params.toString()}`

        const cartItem = new CartItem(
          selectedProduct.value.product.toCartProduct(photo.value, productUrl),
          quantity.value
        )

        await store.dispatch('cart/addToCartNew', cartItem)
        
        // Show success alert with options
        const result = await Swal.fire({
          title: 'Added to Cart!',
          text: `${quantity.value} x ${selectedProduct.value.format} - ${selectedProduct.value.size} - ${selectedProduct.value.finish}`,
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#4a90e2',
          cancelButtonColor: '#6c757d',
          confirmButtonText: 'Go to Cart',
          cancelButtonText: 'Continue Shopping'
        })

        if (result.isConfirmed) {
          router.push({ name: 'cart' })
        }
      } catch (error) {
        if (error.errorType !== 'AlreadyInCart') {
          console.error('Failed to add to cart:', error)
          errorReporting.reportVueError(error, this, 'PrintDetailV2.addToCart')
          await Swal.fire({
            title: 'Error',
            text: 'Failed to add item to cart. Please try again.',
            icon: 'error',
            confirmButtonColor: '#4a90e2'
          })
        } else {
          Swal.fire({
            title: 'Already in Cart',
            text: 'This item is already in your cart.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#4a90e2',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Go to Cart',
            cancelButtonText: 'Continue Shopping'
          }).then((result) => {
            if (result.isConfirmed) {
              router.push({ name: 'cart' })
            }
          })
        }
      }
    }

    // Handle URL parameters for pre-selecting options
    const handleUrlParams = () => {
      const params = new URLSearchParams(window.location.search)
      const format = decodeURIComponent(params.get('format') || '')
      const size = decodeURIComponent(params.get('size') || '')
      const finish = decodeURIComponent(params.get('finish') || '')

      if (format && size && finish && photo.value) {
        const formatObj = photo.value.printFormats.find(f => f.format === format)
        if (formatObj) {
          selectOption(formatObj, size, finish)
        }
      }
    }

    const formatPrice = (price) => {
      return typeof price === 'number' ? `$${(price / 100).toFixed(2)}` : 'N/A'
    }

    const total = computed(() => {
      if (!selectedProduct.value?.product?.unitPrice || !quantity.value) return 0
      return selectedProduct.value.product.unitPrice * quantity.value
    })

    const totalPrice = computed(() => {
      return formatPrice(total.value)
    })

    const toggleFinishDescriptions = (format) => {
      if (openFinishDescriptions.value.has(format)) {
        openFinishDescriptions.value.delete(format)
      } else {
        openFinishDescriptions.value.add(format)
      }
    }

    const isFinishDescriptionOpen = (format) => {
      return openFinishDescriptions.value.has(format)
    }

    const getAllFinishes = (format) => {
      const finishes = new Set()
      format.getSizes().forEach(size => {
        format.getFinishes(size).forEach(finish => finishes.add(finish))
      })
      return Array.from(finishes)
    }

    const formatFinish = (finish => {
      if (!finish) return ''


      return finish
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
    })

    const getFinishDescription = (format, finish) => {
      const formatKey = format.format.toUpperCase()
      const finishKey = finish.toUpperCase();
      if (PRINT_FINISH_DESCRIPTIONS[formatKey] && PRINT_FINISH_DESCRIPTIONS[formatKey][finishKey]) {
        return PRINT_FINISH_DESCRIPTIONS[formatKey][finishKey]
      }
      return ''
    }

    const toggleFormat = (format) => {
      if (openFormats.value.has(format)) {
        openFormats.value.delete(format)
      } else {
        openFormats.value.add(format)
      }
    }

    const isFormatOpen = (format) => {
      return openFormats.value.has(format)
    }

    const isExternalProductUrl = (url) => {
      if (!url) return false
      return url.toLowerCase().startsWith('http://') || url.toLowerCase().startsWith('https://')
    }

    const generateProductUrl = (type, group) => {
      if (!type || !group) return null
      // URL encode the group name to handle spaces and special characters
      const encodedGroup = encodeURIComponent(group)
      return `store/${type}/${encodedGroup}`
    }

    const convertToRouteLocation = (url) => {
      if (!url) return { path: '/' }
      
      // Remove leading slash if present
      const path = url.startsWith('/') ? url.substring(1) : url
      // Split the path into segments
      const segments = path.split('/')
      
      // For a path like 'store/book/Test' or 'products-v2/book/Test'
      if ((segments[0] === 'store' || segments[0] === 'products-v2') && segments.length >= 3) {
        return {
          name: 'products-v2-detail',
          params: {
            type: segments[1] || '',
            group: segments[2] || ''
          }
        }
      }
      return { path }
    }

    const formatFormat = (format => {
        const formatKey = format.toUpperCase();
        if (PRINT_FORMAT_DESCRIPTIONS[formatKey]) {
            return PRINT_FORMAT_DESCRIPTIONS[formatKey].title;
        }
        return format
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    });

    const getFormatDescription = (format) => {
        const formatKey = format.toUpperCase();
        return PRINT_FORMAT_DESCRIPTIONS[formatKey]?.description || '';
    }

    const formatProductType = (type) => {
      return type.charAt(0).toUpperCase() + type.slice(1)
    }

    const toggleSection = (section) => {
      if (openSections.value.has(section)) {
        openSections.value.delete(section)
      } else {
        openSections.value.add(section)
      }
    }

    const isSectionOpen = (section) => {
      return openSections.value.has(section)
    }

    const toggleLocationCollapse = (locationId) => {
      if (collapsedLocations.value.has(locationId)) {
        collapsedLocations.value.delete(locationId);
      } else {
        collapsedLocations.value.add(locationId);
      }
    };

    const isLocationCollapsed = (locationId) => {
      return collapsedLocations.value.has(locationId);
    };

    // Add handler for keyboard shortcuts
    const handleKeyDown = (e) => {
      // Prevent common save shortcuts
      if ((e.ctrlKey || e.metaKey) && (e.key === 's' || e.key === 'S')) {
        e.preventDefault();
      }
      // Close modal on escape
      if (e.key === 'Escape' && showImageModal.value) {
        showImageModal.value = false;
      }
    };

    onMounted(async () => {
      try {
        await loadPhoto()
        handleUrlParams()
        // Open the first format by default
        if (photo.value?.printFormats?.length > 0) {
          openFormats.value.add(photo.value.printFormats[0].format)
        }
      } catch (error) {
        console.error('Error in onMounted:', error)
      }
      window.addEventListener('resize', handleResize);
      handleResize(); // Initial check
      window.addEventListener('keydown', handleKeyDown);
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keydown', handleKeyDown);
    });

    return {
      // UI State
      loading,
      error,
      showImageModal,
      openFinishDescriptions,
      photo,
      formatFormat,
      formatFinish,
      selectedProduct,
      quantity,
      enhanced,
      openSections,
      collapsedLocations,
      totalPrice,
      hasRecommendedOptions,
      
      // Actions
      toggleSection,
      toggleFormat,
      isFormatOpen,
      toggleFinishDescriptions,
      isFinishDescriptionOpen,
      selectOption,
      isSelected,
      addToCart,
      isSectionOpen,
      toggleLocationCollapse,
      isLocationCollapsed,
      formatCategoryName,
      formatPrice,
      formatProductType,
      generateProductUrl,
      getFormatDescription,
      getAllFinishes,
      getFinishDescription,
      isExternalProductUrl,
      convertToRouteLocation,
    }
  }
}
</script>

<style scoped>
.print-detail {
  /*padding: 2rem;*/
  max-width: 1400px;
  margin: 0 auto;
}

.breadcrumb {
  margin-bottom: 2rem;
  font-size: 0.9rem;
}

.breadcrumb a {
  color: #4a90e2;
  text-decoration: none;
}

.breadcrumb span {
  margin: 0 0.5rem;
}

.loading,
.error {
  text-align: center;
  padding: 2rem;
  color: #666;
  font-size: 1.2rem;
}

.photo-title {
  font-size: 2rem;
  font-weight: 500;
  margin: 0 0 1rem 0;
  text-align: left;
  align-self: flex-start;  /* If parent is flex container */
  justify-self: start;     /* If parent is grid container */
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.photo-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-right: 1rem;
}

.main-image-container {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

.main-photo {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.info-section {
  /* removing the base padding */
}

.description {
  color: #666;
  margin-bottom: 2rem;
}

.formats-section,
.related-section,
.locations-section {
  margin-bottom: 2rem;
}

.formats-section h2,
.related-section h3,
.locations-section h3,
.section-title {
  font-size: 1.25rem;  /* Reduced from default size */
  font-weight: 500;
  margin-bottom: 1rem;
}

.format-card {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.format-card h3 {
  margin: 0 0 1rem 0;
  color: #333;
}

.size-item {
  margin-bottom: 1rem;
}

.finishes {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.finish-option {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem;
  border: 2px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 120px;
}

.finish-option:hover {
  border-color: #4a90e2;
}

.finish-option.selected {
  border-color: #4a90e2;
  background-color: #f0f7ff;
}

.finish-option.recommended {
  position: relative;
}

.finish-option.recommended::after {
  content: '★';
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  color: #4caf50;
  cursor: help;
  title: "Recommended option for best quality and value";
}

.finish-name {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.finish-price {
  color: #666;
  font-size: 0.9rem;
}

.finish-descriptions {
  margin: 0.5rem 0 1rem;
}

.toggle-descriptions {
  background: none;
  border: none;
  color: #4a90e2;
  cursor: pointer;
  padding: 0.5rem 0;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.toggle-descriptions:hover {
  text-decoration: underline;
}

.toggle-icon {
  font-size: 0.8rem;
}

.finish-details {
  text-align: start;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background: #fff;
  border-radius: 4px;
}

.finish-detail {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  line-height: 1.4;
}

.finish-detail-name {
  font-weight: 500;
  margin-right: 0.5rem;
}

.finish-detail-description {
  color: #666;
}

.cart-section {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.selected-summary {
  margin-bottom: 1rem;
}

.selected-summary h3 {
  margin: 0 0 0.5rem 0;
}

.selected-summary .price {
  font-size: 1.25rem;
  font-weight: 500;
  color: #4a90e2;
}

.quantity-selector {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.quantity-btn {
  width: 32px;
  height: 32px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quantity {
  font-size: 1.1rem;
  min-width: 2rem;
  text-align: center;
}

.add-to-cart-btn {
  width: 100%;
  padding: 1rem;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-to-cart-btn:hover {
  background: #357abd;
}

.size-header {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.related-list,
.location-list {
  display: grid;
  gap: 1rem;
}

.related-item {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
}

.location-item {
  background: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.related-link,
.external-link {
  color: #4a90e2;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  text-align: left;
}

.external-indicator {
  font-size: 0.8em;
  margin-left: 0.25rem;
}

.no-link {
  color: #666;
  display: block;
  text-align: left;
}

.location-title {
  margin: 0;
  font-size: 1rem;
  color: #4a90e2;
  text-decoration: none;
  text-align: left;
}

.total-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.total-label {
  font-weight: 500;
  font-size: 1.1rem;
}

.total-price {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4a90e2;
}

.format-legend {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.recommended-star {
  color: #4caf50;
  font-size: 1.2rem;
}

.format-description {
  color: #666;
  font-size: 0.95rem;
  margin-bottom: 1rem;
  line-height: 1.6;
  text-align: left;
  white-space: pre-line;
  max-width: 100%;
  overflow-wrap: break-word;
}

.format-header {
  cursor: pointer;
  padding: 0.5rem;
  margin: -0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.format-header:hover {
  background-color: #f0f0f0;
}

.format-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.format-title h3 {
  margin: 0;
  flex-grow: 1;
}

.format-content {
  margin-top: 1rem;
  animation: slideDown 0.2s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 1025px) {
  .info-section {
    padding: 0;
  }

  .info-section > *:not(h1):not(.section-title) {
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0;
  }

  .info-section h1 {
    margin: 0 0 1rem 0;
  }

  .formats-section h2,
  .related-section h3,
  .locations-section h3,
  .section-title {
    margin-left: 0;
    margin-bottom: 0;
    text-align: left;
  }
}

@media (max-width: 1024px) {
  .content {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .photo-section {
    padding-right: 0;
    gap: 0.5rem;
  }

  .info-section {
    padding: 0;
  }

  .formats-section {
    margin-bottom: 0;
  }

  .related-section,
  .locations-section {
    margin-bottom: 0.5rem;
  }

  .format-card {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .cart-section {
    margin-top: 0.5rem;
    padding: 0.5rem;
  }

  .related-item,
  .location-item {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .section-content {
    margin-top: 0.5rem;
  }

  .description {
    margin-bottom: 0.5rem;
  }

  .format-legend {
    margin-bottom: 0.5rem;
  }

  .format-description {
    margin-bottom: 0.5rem;
  }

  .finish-descriptions {
    margin: 0.5rem 0;
  }

  .size-item {
    margin-bottom: 0.5rem;
  }

  .format-content {
    margin-top: 0.5rem;
  }

  .selected-summary {
    margin-bottom: 0.5rem;
  }

  .quantity-selector {
    margin-bottom: 0.5rem;
  }

  .total-section {
    margin-bottom: 0.5rem;
    padding: 0.25rem 0;
  }

  .formats-section h2,
  .related-section h3,
  .locations-section h3,
  .section-title {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 768px) {
  .print-detail {
    padding: 0;
    margin: 0;
  }

  .finish-option {
    min-width: 100px;
    padding: 0.5rem;
  }

  .breadcrumb {
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
  }

  .photo-title {
    font-size: 1.5rem;
    margin: 0 0 0.5rem 0;
    padding: 0 0.5rem;
  }

  .content {
    margin: 0;
  }
}

.locations-header h2 {
  margin: 0;
}

@media (min-width: 1025px) {
  .locations-header .toggle-icon {
    display: none;
  }
}

.location-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  transition: background-color 0.2s;
  padding: 0.5rem;
}

.location-header:hover {
  background-color: #f0f0f0;
}

.section-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  margin: -0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.section-header:hover {
  background-color: #f0f0f0;
}

.section-content {
  animation: slideDown 0.2s ease-out;
  margin-top: 1rem;
}

.section-header .toggle-icon {
  font-size: 0.8rem;
  color: #666;
}

.location-item .section-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 8px;
  background: white;
  border: 1px solid #ddd;
  transition: background-color 0.2s;
}

.location-item .section-header:hover {
  background-color: #f0f0f0;
}

.location-item .location-title {
  margin: 0;
  font-size: 1rem;
  color: #4a90e2;
  text-decoration: none;
}

.location-item .toggle-icon {
  font-size: 0.8rem;
  color: #666;
  margin-left: 0.5rem;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.modal-content {
  position: relative;
  max-width: 95%;
  max-height: 95%;
  animation: modalFadeIn 0.3s ease-out;
}

.image-container {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.image-protection {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

.modal-image {
  max-width: 90vw;
  max-height: 90vh;
  width: auto;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.close-button {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
}

.close-button:hover {
  color: #ddd;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>