<template>
  <div class="container">
    <div class="products-page">
      <!-- Loading State -->
      <div v-if="loading" class="loading-container">
        <div class="loading-spinner"></div>
        <p>Loading products...</p>
      </div>

      <!-- Error State -->
      <div v-if="error" class="error-container">
        <p>{{ error }}</p>
        <button @click="loadProducts" class="retry-button">Retry</button>
      </div>

      <!-- Content -->
      <div v-else class="content">
        <!-- Header -->
        <div class="page-header" v-if="!currentType">
          <h1>Product Types</h1>
        </div>

        <!-- Types Grid -->
        <div v-if="!currentType" class="types-grid">
          <div
            v-for="type in productTypes"
            :key="type.type"
            class="type-card"
            @click="navigateToType(type)"
          >
            <div class="image-container">
              <img 
                :src="getTypeImage(type)" 
                :alt="formatTypeName(type.type)"
              />
              <div class="overlay">
                <h3>{{ formatTypeName(type.type) }}</h3>
                <p class="type-description">{{ type.description }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Group View -->
        <router-view 
          v-if="$route.params.type"
          :key="$route.fullPath"
          :type="currentType"
        ></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { pluralizeType } from '@/store/metadata'

export default {
  name: 'ProductsV2',

  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    // Computed Properties
    const loading = computed(() => store.state.productsv2.loading)
    const error = computed(() => store.state.productsv2.error)
    const productTypes = computed(() => store.state.productsv2.productTypes)
    const isInitialized = computed(() => store.state.productsv2.initialized)
    const currentType = computed(() => {
      if (!route.params.type) return null
      return productTypes.value?.find(t => t.type === route.params.type)
    })
    /*const pageTitle = computed(() =>
      currentType.value ? formatTypeName(route.params.type) : 'Product Collections'
    )*/

    // Methods
    const loadProducts = () => {
      return store.dispatch('productsv2/loadProducts')
    }

    const navigateToType = (type) => {
      router.push({
        name: 'products-v2-type',
        params: { 
          type: type.type
        }
      })
    }

    const formatTypeName = (type) => {
      return pluralizeType(type);
    }

    const getTypeImage = (type) => {
      if (type.imageUrl) {
        return type.imageUrl
      }
      // If no type image, use the first group's image
      if (type.productGroups?.[0]?.groupImageUrl) {
        return type.productGroups[0].groupImageUrl
      }
      // If no group image, use the first product's image
      if (type.productGroups?.[0]?.products?.[0]?.imageUrl) {
        return type.productGroups[0].products[0].imageUrl
      }
      // Return a placeholder if no images
      return '/images/placeholder.jpg'
    }

    // Lifecycle Hooks
    onMounted(() => {
      // Only load if not already initialized
      if (!isInitialized.value) {
        loadProducts()
      }
    })

    // Route Watcher - removed since we now have persistent cache

    // Expose to template
    return {
      loading,
      error,
      productTypes,
      currentType,
      // pageTitle,
      loadProducts,
      navigateToType,
      formatTypeName,
      getTypeImage
    }
  }
}
</script>

<style scoped>
.products-page {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.page-header {
  margin-bottom: 2rem;
  text-align: center;
}

.loading-container, .error-container {
  text-align: center;
  padding: 2rem;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-container {
  background-color: #ffebee;
  color: #c62828;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.retry-button {
  background-color: #c62828;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.types-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.type-card {
  cursor: pointer;
  transition: transform 0.2s;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.type-card:hover {
  transform: translateY(-4px);
}

.image-container {
  position: relative;
  padding-top: 75%;
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(transparent, rgba(0, 0, 0, 2.5));
  padding: 1rem;
  color: white;
}

.overlay h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.5rem;
  color: white;
}

.type-description {
  margin: 0;
  font-size: 0.9rem;
  opacity: 0.9;
  color: white;
}

@media (max-width: 768px) {
  .products-page {
    padding: 1rem;
  }

  .types-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 1rem;
  }
}
</style> 