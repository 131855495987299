import { CartProduct } from "@/models/CartItem";

// Represents an image path with dimensions
export class ImagePath {
    constructor(url, width, height) {
        this.url = url;
        this.width = width;
        this.height = height;
    }

    static fromApiResponse(api) {
        if (!api?.url || !api?.width || !api?.height) {
            throw new Error('ImagePath requires url, width, and height');
        }
        return new ImagePath(api.url, api.width, api.height);
    }
}

export const PRINT_FORMAT_DESCRIPTIONS = {
    ARTPRINT: {
        title: 'Fine Art Prints',

        description: 'We use museum-quality paper to ensure superior color accuracy, deep blacks, and exquisite detail.\n\n' +
            'Printed with premium pigment-based inks, these fine art prints are crafted for long-lasting beauty when properly cared for.\n\n' +
            'Each print is meticulously inspected for quality and color accuracy before being securely packaged for delivery. Available in a variety of sizes, these prints are ready to be framed or displayed in your home, studio, or gallery.\n\nEach print is part of a limited edition, hand-signed by the artist, and accompanied by a certificate of authenticity verifying its provenance and quality.\n\n' +
            'Printed in Moss Beach (16x20 Printed in U.S.A.)'
    },
    PAPER: {
        title: 'Photo Prints',
        description: 'Traditional photo prints on premium photographic paper, offering excellent color accuracy and longevity.\n\nPrinted in U.S.A.'
    },
    TILE: {
        title: 'Metal Tiles',
        description: 'Lightweight metal photo tile.\n\n' +
            'High Quality ChromaLuxe®: Offers clean edges and long-lasting design.\n\n' +
            'Printed in Moss Beach'
    },
    METAL: {
        title: 'Metal Prints',
        description: 'High-definition metal wall art for a clean, modern look. Metal prints are made of high-quality aluminum, with a white base coat that allows for vibrant colors to come through. \n\n' +
            'High Quality ChromaLuxe®: Offers clean edges and long-lasting design.\n\n' +
            'Printed in USA.'
    },
    CANVAS: {
        title: 'Canvas Prints',
        description: 'Premium Poly-Cotton Canvas.'
    },
    // ACRYLIC: {
    //     title: 'Acrylic Prints',
    //     description: 'Vibrant acrylic prints with incredible depth and luminosity, creating a contemporary gallery look.'
    // }
};

export const PRINT_FINISH_DESCRIPTIONS = {
    PAPER: {
        GLOSSY: 'High-gloss finish for maximum color vibrancy and a modern look.',
        MATTE: 'A smooth, reflection-free finish that brings out every fine detail with a soft, natural feel.'
    },
    ARTPRINT: {
        LUSTER: '100% Alpha-Cellulose FineArt paper for a perfect balance of vibrant color and soft texture, offering minimal glare with a subtle sheen. 320gsm',
        GLOSSY: '100% Alpha-Cellulose FineArt paper for enhanced contrast and depth with a reflective, high-shine surface. 325gsm.',
        MATTE: 'A smooth, reflection-free finish that brings out every fine detail with a soft, natural feel. 192gsm'
    },
    TILE: {
        GLOSSY: 'Vibrant and Sharp Image: Glossy finish makes color pop and is scratch, humidity, and fade resistant.',
    },
    METAL: {
        GLOSSY: 'Vibrant and Sharp Image: Glossy finish makes color pop and is scratch, humidity, and fade resistant.',
        MATTE: 'Elegant non-reflective finish, perfect for art galleries and home displays.'
    },
    CANVAS: {},
    // ACRYLIC: {},
};



// Helper function to calculate print area from size string (e.g., "8x10", "16x20")
const calculatePrintArea = (size) => {
    const [width, height] = size.split('x').map(Number);
    if (!width || !height) return 0;
    return width * height;
};

// Helper function to sort sizes by print area (largest to smallest)
const sortSizesByArea = (sizes) => {
    return sizes.sort((a, b) => {
        const areaA = calculatePrintArea(a);
        const areaB = calculatePrintArea(b);
        return areaB - areaA; // Sort in descending order (largest to smallest)
    });
};

// Represents a product variant with specific size and finish
export class PrintProductVariant {
    constructor(stripeProductId, priceId, unitPrice, size, finish, finishDescription, recommended, weight, depth) {
        this.stripeProductId = stripeProductId;
        this.priceId = priceId;
        this.unitPrice = unitPrice;
        this.size = size;
        this.finish = finish;
        this.finishDescription = finishDescription;
        this.recommended = recommended;
        this.format = null; // Will be set by PrintFormat

        const [length, width] = this.size.split('x').map(Number);

        this.weight = weight;
        this.length = length;
        this.width = width
        this.depth = depth;
    }

    static fromApiResponse(api) {
        if (!api?.stripeProductId) {
            throw new Error('PrintProductVariant missing required field - stripeProductId');
        }
        if (!api?.priceId) {
            throw new Error('PrintProductVariant missing required field - priceId');
        }
        if (!api?.unitPrice) {
            throw new Error('PrintProductVariant missing required field - unitPrice');
        }
        if (!api?.size) {
            throw new Error('PrintProductVariant missing required field - size');
        }
        if (!api?.finish) {
            throw new Error('PrintProductVariant missing required field - finish');
        }

        return new PrintProductVariant(
            api.stripeProductId,
            api.priceId,
            api.unitPrice,
            api.size,
            api.finish,
            api.finishDescription || '',
            api.recommended || false.valueOf(),
            api.weight,
            api.depth
        );
    }

    toCartProduct(photoDetail, productUrl = null) {
        const printName = `${photoDetail.title} - ${this.size} ${this.format}, ${this.finish}`;

        return new CartProduct(
            this.stripeProductId,
            this.priceId,
            printName,
            this.unitPrice,
            photoDetail.imagePathSmall.url,
            productUrl,
            'Each',
            null,
            -1,
            [
                `Format: ${this.format}`,
                `Size: ${this.size}`,
                `Finish: ${this.finish}`,
                this.finishDescription ? `Details: ${this.finishDescription}` : null
            ].filter(Boolean),
            this.format,
            this.weight,
            this.length,
            this.width,
            this.depth,
        );
    }
}

// Represents a print format (e.g., "Photo Art Prints (Paper)")
export class PrintFormat {
    constructor(format, description, formatSizes) {
        if (!format) {
            throw new Error('Format is required');
        }
        this.format = format;
        this.description = description || '';
        this.formatSizes = {};
        
        // Transform the formatSizes structure
        Object.entries(formatSizes || {}).forEach(([size, products]) => {
            if (!Array.isArray(products)) {
                throw new Error(`Products for size ${size} must be an array`);
            }
            
            this.formatSizes[size] = products.map(p => {
                const variant = p instanceof PrintProductVariant ? p : PrintProductVariant.fromApiResponse(p);
                variant.format = this.format;
                return variant;
            });
        });
    }

    // Get all available sizes, sorted by print area (largest to smallest)
    getSizes() {
        return sortSizesByArea(Object.keys(this.formatSizes));
    }

    // Get all available finishes for a given size
    getFinishes(size) {
        const products = this.formatSizes[size] || [];
        return [...new Set(products.map(p => p.finish))];
    }

    // Get a specific product variant by size and finish
    getProduct(size, finish) {
        const products = this.formatSizes[size] || [];
        return products.find(p => p.finish === finish) || null;
    }

    // Get recommended product for this format, if any
    getRecommendedProduct() {
        const allProducts = Object.values(this.formatSizes).flat();
        return allProducts.find(p => p.recommended) || null;
    }

    static fromApiResponse(api) {
        if (!api?.format || !api?.formatSizes) {
            throw new Error('PrintFormat requires format and formatSizes');
        }
        return new PrintFormat(api.format, api.description, api.formatSizes);
    }
}

// Represents a related product (like a book)
export class RelatedProduct {
    constructor(stripeProductId, type, group, groupImageUrl, productUrl) {
        this.stripeProductId = stripeProductId;
        this.type = type;
        this.group = group;
        this.groupImageUrl = groupImageUrl;
        this.productUrl = productUrl;
    }

    static fromApiResponse(api) {
        if (!api?.stripeProductId || !api?.type || !api?.group) {
            throw new Error('RelatedProduct missing required fields');
        }
        return new RelatedProduct(
            api.stripeProductId,
            api.type,
            api.group,
            api.groupImageUrl,
            api.productUrl
        );
    }
}

// Represents a location where the print is displayed
export class PrintLocation {
    constructor(id, title, description, url, address, coordinates, placeId, mapUrl, isActive) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.url = url;
        this.address = address || null;
        this.coordinates = coordinates || null;
        this.placeId = placeId || null;
        this.mapUrl = mapUrl || null;
        this.isActive = isActive !== undefined ? isActive : true;
    }

    static fromApiResponse(api) {
        if (!api?.id || !api?.title) {
            throw new Error('PrintLocation requires id and title');
        }
        return new PrintLocation(
            api.id,
            api.title,
            api.description || '',
            api.url || '',
            api.address || null,
            api.coordinates || null,
            api.placeId || null,
            api.mapUrl || null,
            api.isActive !== undefined ? api.isActive : true
        );
    }

    // Helper method to get formatted address
    getFormattedAddress() {
        if (!this.address) return '';
        
        const parts = [
            this.address.street,
            this.address.city,
            this.address.state,
            this.address.zipCode,
            this.address.country
        ].filter(Boolean);
        
        return parts.join(', ');
    }

    // Helper method to get Google Maps URL
    getGoogleMapsUrl() {
        if (this.placeId) return `https://www.google.com/maps/place/?q=place_id:${this.placeId}`;
        if (this.coordinates && this.coordinates.lat && this.coordinates.lng) {
            return `https://www.google.com/maps/search/?api=1&query=${this.coordinates.lat},${this.coordinates.lng}`;
        }
        if (this.mapUrl) return this.mapUrl;
        
        // Only create an address-based URL if we have an actual address with meaningful parts
        if (this.address) {
            const addressStr = this.getFormattedAddress();
            if (addressStr && addressStr.length > 3) { // Simple check to ensure we have a meaningful address
                return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressStr)}`;
            }
        }
        
        return '';
    }
}

// Represents a single photo with all its print options
export class PhotoV2 {
    constructor(id, title, description, imagePathSmall, imagePathLarge, printFormats, printProducts, printLocations) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.imagePathSmall = imagePathSmall;
        this.imagePathLarge = imagePathLarge;
        this.printFormats = printFormats || [];
        this.printProducts = printProducts || [];
        this.printLocations = printLocations || [];
    }

    static fromApiResponse(api) {
        if (!api?.id) {
            throw new Error('Photo missing required field - id');
        }
        if (!api?.title) {
            throw new Error('Photo missing required field - title');
        }
        if (!api?.imagePathSmall) {
            throw new Error('Photo missing required field - imagePathSmall');
        }

        return new PhotoV2(
            api.id,
            api.title,
            api.description || '',
            ImagePath.fromApiResponse(api.imagePathSmall),
            api.imagePathLarge ? ImagePath.fromApiResponse(api.imagePathLarge) : null,
            (api.printFormats || []).map(f => PrintFormat.fromApiResponse(f)),
            (api.printProducts || []).map(p => RelatedProduct.fromApiResponse(p)),
            (api.printLocations || []).map(l => PrintLocation.fromApiResponse(l))
        );
    }

    // Get recommended products across all formats
    getRecommendedProducts() {
        const recommended = [];
        for (const format of this.printFormats) {
            const product = format.getRecommendedProduct();
            if (product) {
                recommended.push({
                    format: format.format,
                    product
                });
            }
        }
        return recommended;
    }
}

// Represents a category of photos
export class CategoryV2 {
    constructor(category, description, photos) {
        this.category = category;
        this.description = description;
        this.photos = photos || [];
    }

    static fromApiResponse(api) {
        if (!api?.category) {
            throw new Error('Category requires category name');
        }
        return new CategoryV2(
            api.category,
            api.description || '',
            (api.photos || []).map(p => PhotoV2.fromApiResponse(p))
        );
    }
}
