<template>
  <div v-if="category" class="print-category">
    <!-- Header -->
    <div class="category-header">
      <h1>{{ formatCategoryName(category.category) }}</h1>
      <p v-if="category.description" class="category-description">
        {{ category.description }}
      </p>
      <div class="breadcrumb">
        <router-link :to="{ name: 'prints-v2' }">All Categories</router-link>
        <span> > </span>
        <span>{{ formatCategoryName(category.category) }}</span>
      </div>
    </div>

    <!-- Photos Grid -->
    <div v-if="category.photos?.length" class="photos-grid">
      <div
        v-for="photo in category.photos"
        :key="photo.id"
        class="photo-card"
        @click="navigateToPhoto(photo)"
      >
        <div class="image-container">
          <img 
            v-if="photo.imagePathSmall?.url" 
            :src="photo.imagePathSmall.url" 
            :alt="photo.title" 
          />
          <div class="overlay">
            <h3>{{ photo.title }}</h3>
            <p>{{ photo.description }}</p>
            <div v-if="photo.recommendedProducts?.length" class="recommended-badge">
              Staff Pick
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-photos">
      No photos available in this category.
    </div>
  </div>
  <div v-else class="error-state">
    Category not found.
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'PrintCategoryV2',
  props: {
    category: {
      type: Object,
      required: true,
      validator: (value) => {
        return value && typeof value.category === 'string'
      }
    }
  },
  setup(props) {
    const router = useRouter()

    const navigateToPhoto = (photo) => {
      if (!photo?.id) return;
      router.push({
        name: 'prints-v2-photo',
        params: {
          category: props.category.category,
          photoId: photo.id
        }
      })
    }

    const formatCategoryName = (category) => {
      if (!category) return '';
      return category
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }

    return {
      navigateToPhoto,
      formatCategoryName
    }
  }
}
</script>

<style scoped>
.print-category {
  padding: 1rem;
}

.category-header {
  text-align: center;
  margin-bottom: 2rem;
}

.category-description {
  max-width: 800px;
  margin: 1rem auto;
  color: #666;
}

.breadcrumb {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.breadcrumb a {
  color: #4a90e2;
  text-decoration: none;
}

.breadcrumb span {
  margin: 0 0.5rem;
}

.photos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.photo-card {
  cursor: pointer;
  transition: transform 0.2s;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.photo-card:hover {
  transform: translateY(-4px);
}

.image-container {
  position: relative;
  padding-top: 75%;
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  padding: 1rem;
  color: white;
}

.overlay h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.25rem;
  color: white;
}

.overlay p {
  margin: 0;
  font-size: 0.875rem;
  opacity: 0.9;
  color: white;
}

.recommended-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #4caf50;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: bold;
}

.no-photos,
.error-state {
  text-align: center;
  padding: 2rem;
  color: #666;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .photos-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }
}
</style> 