<template>
  <div v-if="type" class="product-type">
    <!-- Header -->
    <div class="type-header">
      <h1>{{ formatTypeName(type.type) }}</h1>
      <p v-if="type.description" class="type-description">
        {{ type.description }}
      </p>
      <div class="breadcrumb">
        <router-link :to="{ name: 'products-v2' }">All Types</router-link>
        <span> > </span>
        <span>{{ formatTypeName(type.type) }}</span>
      </div>
    </div>

    <!-- Groups Grid -->
    <div v-if="type.productGroups?.length" class="groups-grid">
      <router-link
        v-for="group in type.productGroups"
        :key="group.group"
        :to="{ name: 'products-v2-detail', params: { type: type.type, group: group.group }}"
        class="group-card"
      >
        <div class="image-container">
          <img 
            :src="getGroupImage(group)" 
            :alt="formatGroupName(group.group)"
          />
          <div class="overlay">
            <h3>{{ formatGroupName(group.group) }}</h3>
          </div>
        </div>
      </router-link>
    </div>
    <div v-else class="no-groups">
      No product groups available.
    </div>
  </div>
  <div v-else class="error-state">
    Type not found.
  </div>
</template>

<script>
import { pluralizeType } from '@/store/metadata';

export default {
  name: 'ProductGroupV2',
  props: {
    type: {
      type: Object,
      required: true
    }
  },

  setup() {
    const formatGroupName = (group) => {
      return group
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }

    const formatTypeName = (type) => {
      return pluralizeType(type);
    }

    const getGroupImage = (group) => {
      if (group.groupImageUrl) {
        return group.groupImageUrl
      }
      // If no group image, use the first product's image
      if (group.products?.[0]?.imageUrl) {
        return group.products[0].imageUrl
      }
      // Return a placeholder if no images
      return '/images/placeholder.jpg'
    }

    return {
      formatGroupName,
      formatTypeName,
      getGroupImage
    }
  }
}
</script>

<style scoped>
.product-type {
  padding: 1rem;
}

.type-header {
  text-align: center;
  margin-bottom: 2rem;
}

.type-description {
  max-width: 800px;
  margin: 1rem auto;
  color: #666;
}

.breadcrumb {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.breadcrumb a {
  color: #4a90e2;
  text-decoration: none;
}

.breadcrumb span {
  margin: 0 0.5rem;
}

.groups-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.group-card {
  text-decoration: none;
  color: inherit;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.group-card:hover {
  transform: translateY(-4px);
}

.image-container {
  position: relative;
  padding-top: 75%;
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  padding: 1rem;
  color: white;
}

.overlay h3 {
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
  color: white;
}

.no-groups,
.error-state {
  text-align: center;
  padding: 2rem;
  color: #666;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .groups-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }
}
</style> 