import axios from "axios";
import errorReporting from "@/errorReporting";

export default {
    namespaced: true,
    state: {
        orders: [],
    },
    getters: {
        orders(state) {
            return state.orders;
        },
    },
    mutations: {
        orders(state, orders) {
            state.orders = orders;
        },
    },
    actions: {
        async findOrder(context, payload) {
            const url = `${process.env.VUE_APP_API_URL}/order?sessionId=${payload.sessionId}`;
            const headers = {
                'x-api-key': process.env.VUE_APP_API_KEY
            }

            try {
                const sessionId = payload.sessionId
                const orders = await context.getters.orders;

                let order = orders.find(order => order.sessionId === sessionId);
                if (!order) {
                    //     not found. load from api
                    const response = await axios.get(url, {headers: headers});
                    const order = response.data
                    if (order.sessionId === sessionId) {
                        orders.push(order)
                        return order;
                    } else {
                        errorReporting.reportApiError(`order.CheckoutSession[${order.sessionId}] !== payload.sessionId[${sessionId}]`, {
                            url: url,
                            method: 'POST',
                            headers: headers
                        })
                        return null;
                    }
                }
                return order;
            } catch (error) {
                console.error(`Exception finding order ${payload.sessionId}`, error);
                errorReporting.reportApiError(error, {
                    url: url,
                    method: 'POST',
                    headers: headers
                })
                return null;
            }
        }
    }
}