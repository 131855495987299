import { createStore } from 'vuex'
import cart from './cart'
import catalog from './catalog'
import orders from './orders'
import images from './images'
import gallery from './gallery'
import printsv2 from './printsv2'
import productsv2 from './productsv2'
import metadata from './metadata'

const store = createStore({
    modules: {
        cart,
        catalog,
        orders,
        images,
        gallery,
        printsv2,
        productsv2,
        metadata
    }
})

// Load saved cart state when store is created
store.commit('cart/loadFromLocalStorage');

// Initialize enhanced mode state
store.commit('metadata/INIT_ENHANCED_MODE');

export default store;