import axios from 'axios';

/**
 * Error reporting service to track frontend errors
 */
class ErrorReportingService {
    constructor() {
        this.initialized = false;
        this.queue = [];
        this.isProcessing = false;
        this.MAX_RETRIES = 3;  // Maximum number of retries per error
        this.MAX_QUEUE_SIZE = 100;  // Maximum number of errors to keep in queue

        // Initialize global error handlers
        this.initializeErrorHandlers();
    }

    initializeErrorHandlers() {
        if (this.initialized) return;
        
        // Handle uncaught errors
        window.addEventListener('error', (event) => {
            this.queueError({
                type: 'uncaught_error',
                error: {
                    message: event.message,
                    stack: event.error?.stack,
                    filename: event.filename,
                    lineno: event.lineno,
                    colno: event.colno
                }
            });
        });

        // Handle unhandled promise rejections
        window.addEventListener('unhandledrejection', (event) => {
            this.queueError({
                type: 'unhandled_promise_rejection',
                error: {
                    message: event.reason?.message || 'Unknown promise rejection',
                    stack: event.reason?.stack
                }
            });
        });

        // Start processing queued errors
        this.processQueue();
        this.initialized = true;
    }

    /**
     * Queue an error for reporting
     * @param {Object} errorData - Error information to be queued
     */
    queueError(errorData) {
        const payload = {
            ...errorData,
            timestamp: new Date().toISOString(),
            userAgent: navigator.userAgent,
            url: window.location.href,
            retryCount: 0  // Initialize retry counter
        };
        
        // If queue is at max capacity, remove oldest error
        if (this.queue.length >= this.MAX_QUEUE_SIZE) {
            console.warn(`Error queue at maximum capacity (${this.MAX_QUEUE_SIZE}). Dropping oldest error.`);
            this.queue.shift();
        }
        
        this.queue.push(payload);
        
        // Ensure queue processing is running
        if (!this.isProcessing) {
            this.processQueue();
        }
    }

    /**
     * Process queued errors asynchronously
     */
    async processQueue() {
        if (this.isProcessing || this.queue.length === 0) return;

        this.isProcessing = true;

        while (this.queue.length > 0) {
            const error = this.queue[0];
            try {
                const payload = {
                    error: error
                }

                const headers = {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.VUE_APP_API_KEY
                }

                const url = process.env.VUE_APP_API_URL + "/error";
                await axios.post(url, payload, {
                    headers: headers
                });

                this.queue.shift(); // Remove the successfully processed error
            } catch (err) {
                console.warn('Failed to report error:', {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: err.response?.data,
                    retryCount: error.retryCount
                });

                const currentError = this.queue.shift();
                currentError.retryCount = (currentError.retryCount || 0) + 1;
                
                if (currentError.retryCount < this.MAX_RETRIES) {
                    // Move failed error to the end of the queue for retry
                    this.queue.push(currentError);
                    // Wait before retrying with exponential backoff
                    await new Promise(resolve => setTimeout(resolve, Math.min(5000 * Math.pow(2, currentError.retryCount), 30000)));
                } else {
                    console.warn(`Error reporting failed after ${this.MAX_RETRIES} attempts, dropping error:`, currentError);
                }
            }
        }

        this.isProcessing = false;
    }

    /**
     * Report a Vue component error
     * @param {Error} error - The error that was caught
     * @param {import('vue').ComponentPublicInstance} instance - Vue component instance
     * @param {string} info - Error information
     */
    reportVueError(error, instance, info) {
        this.queueError({
            type: 'vue',
            error: {
                message: error.message,
                stack: error.stack,
                componentName: instance?.$options?.name || 'AnonymousComponent',
                info: info,
                propsData: instance?.$props,
                componentData: instance?.$data
            }
        });
    }

    /**
     * Report an API error
     * @param {Error} error - The API error
     * @param {Object} requestInfo - Information about the failed request
     */
    reportApiError(error, requestInfo) {
        this.queueError({
            type: 'api',
            error: {
                message: error.message,
                stack: error.stack,
                status: error.response?.status,
                statusText: error.response?.statusText,
                data: error.response?.data
            },
            context: {
                request: requestInfo
            }
        });
    }

    /**
     * Install the error reporting service as a Vue plugin
     * @param {import('vue').App} app - Vue application instance
     */
    install(app) {
        // Set up global error handler
        app.config.errorHandler = (error, instance, info) => {
            this.reportVueError(error, instance, info);
            console.error('Vue Error:', error);
        };

        // Make the error reporting service available in all components
        app.config.globalProperties.$errorReporting = this;
    }
}

// Create a singleton instance
const errorReporting = new ErrorReportingService();

export default errorReporting; 