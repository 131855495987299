import { ProductTypeV2 } from '@/models/ProductV2'
import axios from 'axios'
import errorReporting from "@/errorReporting";

export default {
    namespaced: true,
    state: {
        productTypes: [],
    },
    getters: {
    },
    mutations: {
        SET_PRODUCT_TYPES(state, productTypes) {
            state.productTypes = productTypes;
        },
    },
    actions: {
        async loadProducts({commit, state}) {
            // If already initialized and have data, just return
            if (state.productTypes.length > 0) {
                return state.productTypes
            }

            const url = '/products/new_products.json'
            try {
                const {data} = await axios.get(url)
                const productTypes = data.map(productType => ProductTypeV2.fromApiResponse(productType))
                commit('SET_PRODUCT_TYPES', productTypes);
                return productTypes;
            } catch (error) {
                commit('SET_PRODUCT_TYPES', []);
                console.error('Error loading products:', error);
                errorReporting.reportApiError(error, {
                    url: url,
                    method: 'GET',
                    headers: {}
                })
                throw new Error('An error occurred while loading prints');
            }
        },
    }
}