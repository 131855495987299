import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/store'
import { pluralizeType } from '@/store/metadata'
import PrintsV2 from '@/views/PrintsV2.vue'
import PrintCategoryV2 from '@/components/printsv2/PrintCategoryV2.vue'
import PrintDetailV2 from "@/components/printsv2/PrintDetailV2.vue";
import ProductsV2 from "@/views/ProductsV2.vue";
import ProductGroupV2 from "@/components/productsv2/ProductGroupV2.vue"
import ProductDetailV2 from "@/components/productsv2/ProductDetailV2.vue"

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/NewHome.vue'),
    meta: {
      title: 'Moss Beach Life - Home',
      metaTags: [
        {
          name: 'description',
          content: 'Welcome to Moss Beach Life - Photography and products from the beautiful Moss Beach area.'
        },
        {
          property: 'og:description',
          content: 'Welcome to Moss Beach Life - Photography and products from the beautiful Moss Beach area.'
        }
      ]
    }
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/GalleryView.vue'),
    meta: {
      title: 'Moss Beach Life - Gallery',
      metaTags: [
        {
          name: 'description',
          content: 'Browse our collection of beautiful photographs from Moss Beach.'
        },
        {
          property: 'og:description',
          content: 'Browse our collection of beautiful photographs from Moss Beach.'
        }
      ]
    }
  },
  {
    path: '/prints',
    name: 'prints-v2',
    component: PrintsV2,
    meta: {
      title: 'Moss Beach Life - Photo Prints',
      metaTags: [
        {
          name: 'description',
          content: 'Shop for high-quality photo prints from Moss Beach.'
        },
        {
          property: 'og:description',
          content: 'Shop for high-quality photo prints from Moss Beach.'
        }
      ]
    },
    children: [
      {
        path: ':category',
        name: 'prints-v2-category',
        component: PrintCategoryV2,
        props: true,
        meta: { 
          category: true,
          title: 'Moss Beach Life - Print Category'
        }
      },
      {
        path: ':category/:photoId',
        name: 'prints-v2-photo',
        component: PrintDetailV2,
        meta: { 
          category: true,
          title: 'Moss Beach Life - Print Detail'
        },
        props: route => ({
          ...route.params,
          photoId: route.params.photoId.toUpperCase()
        })
      }
    ]
  },
  {
    path: '/store',
    name: 'products-v2',
    component: ProductsV2,
    meta: {
      title: 'Moss Beach Life - Store',
      metaTags: [
        {
          name: 'description',
          content: 'Shop for products featuring Moss Beach photography.'
        },
        {
          property: 'og:description',
          content: 'Shop for products featuring Moss Beach photography.'
        }
      ]
    },
    children: [
      {
        path: ':type',
        name: 'products-v2-type',
        component: ProductGroupV2,
        props: true,
        meta: { 
          type: true,
          title: 'Moss Beach Life - Product Category'
        }
      },
      {
        path: ':type/:group',
        name: 'products-v2-detail',
        component: ProductDetailV2,
        props: true,
        meta: {
          title: 'Moss Beach Life - Product Detail'
        }
      }
    ]
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import(/* webpackChunkName: "checkout" */ '../views/NewCart.vue'),
    meta: {
      title: 'Moss Beach Life - Shopping Cart',
      metaTags: [
        {
          name: 'description',
          content: 'Your shopping cart at Moss Beach Life.'
        },
        {
          property: 'og:description',
          content: 'Your shopping cart at Moss Beach Life.'
        }
      ]
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/OrderView.vue'),
    meta: {
      title: 'Moss Beach Life - Your Orders',
      metaTags: [
        {
          name: 'description',
          content: 'View your orders from Moss Beach Life.'
        },
        {
          property: 'og:description',
          content: 'View your orders from Moss Beach Life.'
        }
      ]
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
    meta: {
      title: 'Moss Beach Life - Contact Us',
      metaTags: [
        {
          name: 'description',
          content: 'Get in touch with Moss Beach Life.'
        },
        {
          property: 'og:description',
          content: 'Get in touch with Moss Beach Life.'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: 'Moss Beach Life - About Us',
      metaTags: [
        {
          name: 'description',
          content: 'Learn about Moss Beach Life and our mission.'
        },
        {
          property: 'og:description',
          content: 'Learn about Moss Beach Life and our mission.'
        }
      ]
    }
  },
  // This should be the last route
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
]

// Function to update page metadata - for backward compatibility
// export function updatePageMetaInfo(title, description) {
//   // Use the store's action
//   store.dispatch('metadata/updateMetaInfo', { title, description });
// }

// Export pluralizeType for component use
export { pluralizeType }

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach(async (to, from, next) => {
  // Skip Vue router handling for paths starting with /share
  // This allows the static HTML files to be served directly
  if (to.path.startsWith('/share')) {
    // Let the request go through to the static file server (S3)
    window.location.href = to.fullPath;
    return;
  }

  // Handle case-insensitive photo ID redirection
  if (to.name === 'prints-v2-photo' && to.params.photoId) {
    const photoId = to.params.photoId;

    // If the photoId is lowercase and not found, try uppercase version
    if (photoId === photoId.toLowerCase() && photoId !== photoId.toUpperCase()) {
      // Load categories if not already loaded
      if (!store.state.printsv2.categories?.length) {
        try {
          await store.dispatch('printsv2/loadCategories');
        } catch (error) {
          next();
          return;
        }
      }
      
      const category = store.state.printsv2.categories?.find(
        c => c.category === to.params.category
      );
      
      if (category) {
        const photo = category.photos?.find(
          p => p.id.toUpperCase() === photoId.toUpperCase()
        );
        
        if (photo) {
          // If we found the photo with uppercase ID, redirect to uppercase version
          const newPath = `/prints/${to.params.category}/${photoId.toUpperCase()}`;
          if (to.fullPath !== newPath) {
            window.location.href = newPath;
            return;
          }
        }
      }
    }
  }

  // Process enhanced mode query parameter on every route change
  if (to.query.mode === 'enhanced') {
    store.dispatch('metadata/setEnhancedMode', true);
  }

  // Update metadata using our store
  store.dispatch('metadata/updateRouteMetadata', { route: to });
  next();
});

export default router
