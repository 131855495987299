import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import VueGtag from 'vue-gtag';
import LazyLoadDirective from "@/directives/LazyLoadDirective";
import "bootstrap"
import errorReporting from "./errorReporting";

// Initialize app state from localStorage
// This needs to happen before app mount
store.commit('metadata/INIT_ENHANCED_MODE');

const app = createApp(App)

app.directive("lazyload", LazyLoadDirective)
    .use(store)
    .use(router)
    .use(errorReporting)
    .use(VueGtag, {
        config: { id: "G-25K8RL069G" }
    }, router)

app.mount('#app')
