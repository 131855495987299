import axios from "axios";
import { CartItem } from "../models/CartItem";
import errorReporting from "@/errorReporting";

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

// Helper function to save cart state to localStorage
const saveCartToStorage = (cart, shipping) => {
  const cartState = { cart, shipping };
  localStorage.setItem('currentCart', JSON.stringify(cartState));
};

export default {
  namespaced: true,
  state: {
    cart: [],
    shipping: 'TBD',
    minimumOrder: 2500
  },
  getters: {
    cart: (state) => state.cart,
    shipping: (state) => state.shipping,
    minimumOrder: (state) => state.minimumOrder,
  },
  mutations: {
    cart(state, cart) {
      state.cart = cart;
      saveCartToStorage(state.cart, state.shipping);
    },
    shipping(state, shipping) {
      state.shipping = shipping;
      saveCartToStorage(state.cart, state.shipping);
    },
    loadFromLocalStorage(state) {
      const savedCart = localStorage.getItem('currentCart');
      if (savedCart) {
        const cartState = JSON.parse(savedCart);
        if (cartState.cart) {
          state.cart = cartState.cart.map(item => CartItem.fromLocalStorage(item));
          state.shipping = cartState.shipping || 'TBD';
        }
      }
    }
  },
  actions: {
    async emptyCart(context) {
      let cart = context.getters["cart"];
      cart.length = 0;
      await context.commit('shipping', 'TBD');
      localStorage.removeItem('currentCart'); // Clear the saved cart when emptying
    },
    async addToCartNew(context, cartItem) {
      let cart = context.getters["cart"];

      // Ensure cartItem is a CartItem instance
      const newItem = cartItem instanceof CartItem ? cartItem : new CartItem(cartItem.product, cartItem.quantity);

      // Find the item in the cart by productId
      const existingItem = cart.find((item) =>
          item.product.productId === newItem.product.productId
      );

      if (existingItem) {
        //  if quantity is different - adjust cart quantity to match
        if (existingItem.quantity !== newItem.quantity) {
          existingItem.quantity = newItem.quantity;
          const shipping = await context.dispatch('calculateShipping', cart)
          await context.commit('shipping', shipping);
          return 'updated'
        } else {
          throw {
            errorType: 'AlreadyInCart',
            message: 'Item already in cart and quantities match',
            item: existingItem
          };
        }
      } else {
        // If the item doesn't exist, add it to the cart
        cart.push(newItem);
        const shipping = await context.dispatch('calculateShipping', cart)
        await context.commit('shipping', shipping);

        return 'added'
      }
    },
    async incrementQty(context, productId){
      let cart = context.getters["cart"];
      const existingItem = cart.find((item) =>
          item.product.productId === productId
      );

      if (existingItem) {
        existingItem.increaseQuantity();
        const shipping = await context.dispatch('calculateShipping', cart)
        await context.commit('shipping', shipping);
      }
    },
    async decrementQty(context, productId) {
      let cart = context.getters["cart"];
      const existingItem = cart.find((item) => item.product.productId === productId);

      if (existingItem) {
        existingItem.decreaseQuantity();
        if (existingItem.quantity <= 0) {
          cart.splice(cart.indexOf(existingItem), 1);
        }
        const shipping = await context.dispatch('calculateShipping', cart);
        await context.commit('cart', cart);
        await context.commit('shipping', shipping);
      } else {
        throw new Error('Item not found in cart to decrement');
      }
    },
    async calculateShipping(context, cart) {
      const shippingUrl = process.env.VUE_APP_API_URL + "/shipping";
      const headers = {
        'x-api-key': process.env.VUE_APP_API_KEY
      }

      try {
        // Ensure all items are CartItem instances
        const cartItems = cart.map(item => {
          return item instanceof CartItem ? item : new CartItem(item.product, item.qty);
        });

        const payload = {
          items: cartItems.map(item => ({
            product: item.product,
            qty: item.quantity
          }))
        };

        // get list of shipping options for current cart
        const response = (await axios.post(shippingUrl, payload, {headers: headers}));

        // given options, calculate min and max
        let sMin = null;
        let sMax = null;
        response.data.forEach((rate) => {
          const d = rate.shipping_rate_data;
          const amount = parseInt(d.fixed_amount.amount);
          if (sMin === null || amount < sMin) {
            sMin = amount;
          }
          if (sMax === null || amount > sMax) {
            sMax = amount;
          }
        })

        if (sMin != null && sMax != null) {
          if (sMin !== sMax) {
            return `TBD (${formatter.format(sMin > 0 ? sMin / 100 : 0)} - ${formatter.format(sMax > 0 ? sMax / 100 : 0)})`;
          } else {
            return `TBD (${formatter.format(sMax > 0 ? sMax / 100 : 0)})`;
          }

        } else {
          return 'TBD';
        }
      } catch (error) {
        console.error("Exception calculating shipping", error);
        errorReporting.reportApiError(error, {
          url: shippingUrl,
          method: 'POST',
          headers: headers
        })
        throw error;
      }
    },
    async redirectToStripe(context, { promoCode, specialInstructions }) {
      const url = process.env.VUE_APP_API_URL + "/order";
      const headers = {
        'x-api-key': process.env.VUE_APP_API_KEY
      }

      const cart = context.getters["cart"];
      const shipping = context.getters["shipping"];

      const metadata = {
        promoCode: promoCode || null,
        specialInstructions: specialInstructions || null
      };
      try {
        // Ensure all items are CartItem instances
        const cartItems = cart.map(item => {
          return item instanceof CartItem ? item : new CartItem(item.product, item.qty);
        });

        const payload = {
          items: cartItems.map(item => ({
            product: {
              ...item.product,
              productUrl: new URL(item.product.productUrl, process.env.VUE_APP_HOST_URL).href
            },
            qty: item.quantity
          })),
          metadata: metadata,
          host: process.env.VUE_APP_HOST_URL
        };


        const response = await axios.post(url, payload, {
          headers: headers
        });

        // Save cart state for recovery
        const cartState = {
          cart: cartItems,
          promoCode,
          specialInstructions,
          shipping
        };
        localStorage.setItem('cart', JSON.stringify(cartState));

        return response.data.url;
      } catch (error) {
        console.error("Exception preparing checkout", error);
        errorReporting.reportApiError(error, {
          url: url,
          method: 'POST',
          headers: headers
        })
        throw error;
      }
    }
  },
}
