// Helper function to convert slugs to title case
const slugToTitle = (slug) => {
  return slug
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Helper function to pluralize product types
export const pluralizeType = (type) => {
  // Handle common irregular plurals and specific product type cases
  const irregularPlurals = {
    'book': 'Books',
    'box': 'Boxes',
    'canvas': 'Canvas', // Some words stay the same
    'mouse': 'Mice',
    'photo': 'Photos',
    'dress': 'Dresses',
    'watch': 'Watches',
    'glass': 'Glasses',
    'brush': 'Brushes',
    'patch': 'Patches',
    'merchandise': 'Merchandise', // Uncountable nouns
    'apparel': 'Apparel',
    'clothing': 'Clothing',
    'footwear': 'Footwear',
    'jewelry': 'Jewelry',
    'art': 'Art',
    'decor': 'Decor'
  };

  // First convert to title case
  const titleCase = slugToTitle(type);
  
  // Check if we have a predefined plural for this type
  if (irregularPlurals[type.toLowerCase()]) {
    return irregularPlurals[type.toLowerCase()];
  }
  
  // Apply basic English pluralization rules
  if (titleCase.endsWith('y') && !['a', 'e', 'i', 'o', 'u'].includes(titleCase.charAt(titleCase.length - 2).toLowerCase())) {
    // City -> Cities
    return titleCase.slice(0, -1) + 'ies';
  } else if (titleCase.endsWith('s') || titleCase.endsWith('x') || titleCase.endsWith('z') || 
             titleCase.endsWith('ch') || titleCase.endsWith('sh')) {
    // Bus -> Buses, Box -> Boxes, etc.
    return titleCase + 'es';
  } else {
    // Regular plural: add 's'
    return titleCase + 's';
  }
};

/**
 * Centralized title formatter that handles different types of title inputs
 * All title formatting logic should be here - not spread across components
 */
const formatTitle = (titleData) => {
  // Default site name
  const siteName = 'Moss Beach Life';
  
  // If title is just a string, format it with site name
  if (typeof titleData === 'string') {
    return `${titleData} | ${siteName}`;
  }
  
  // Handle object with structured data
  if (typeof titleData === 'object') {
    // Different title components
    const parts = [];
    
    // Format based on page type
    switch (titleData.pageType) {
      case 'store': 
        if (titleData.productType && titleData.productGroup) {
          // Product detail page (e.g., "Books - Coastal Collection")
          const typeTitle = pluralizeType(titleData.productType);
          const groupTitle = slugToTitle(titleData.productGroup);
          parts.push(`${typeTitle} - ${groupTitle}`);
        } else if (titleData.productType) {
          // Product type page (e.g., "Books")
          parts.push(pluralizeType(titleData.productType));
        } else {
          // Main store page
          parts.push('Store');
        }
        break;
        
      case 'prints':
        if (titleData.printCategory && titleData.printTitle) {
          // Print detail page (e.g., "Sunset Glow - Golden Horizons")
          parts.push(`${titleData.printTitle} - ${slugToTitle(titleData.printCategory)}`);
        } else if (titleData.printCategory) {
          // Print category page (e.g., "Golden Horizons Collection")
          parts.push(`${slugToTitle(titleData.printCategory)} Collection`);
        } else {
          // Main prints page
          parts.push('Photo Prints');
        }
        break;
        
      case 'gallery':
        if (titleData.galleryCategory) {
          // Gallery category
          parts.push(`${slugToTitle(titleData.galleryCategory)} Gallery`);
        } else {
          // Main gallery page
          parts.push('Gallery');
        }
        break;
        
      case 'cart':
        parts.push('Shopping Cart');
        break;
        
      case 'orders':
        parts.push('Your Orders');
        break;
        
      case 'contact':
        parts.push('Contact Us');
        break;
        
      case 'about':
        parts.push('About Us');
        break;
        
      case 'home':
        // For homepage, just use site name
        return siteName;
    }
    
    // If we have parts, join them and add site name
    if (parts.length > 0) {
      return `${parts.join(' ')} | ${siteName}`;
    }
  }

  // Default fallback
  return siteName;
};

export default {
  namespaced: true,
  
  state: () => ({
    titleData: null,
    metaTags: [],
    defaultTitle: 'Moss Beach Life',
    enhancedMode: false
  }),
  
  getters: {
    isEnhancedMode: state => state.enhancedMode,
  },
  
  mutations: {
    SET_TITLE_DATA(state, titleData) {
      // Store the raw title data for reference
      state.titleData = titleData;
      
      // Set the title in the document
      document.title = formatTitle(titleData);
    },

    SET_ENHANCED_MODE(state, enabled) {
      // console.log(`Setting enhanced mode to: ${enabled}`);
      state.enhancedMode = enabled;
      // Store in localStorage so it persists between page refreshes
      // localStorage.setItem('enhancedMode', JSON.stringify(enabled));
    },
    
    INIT_ENHANCED_MODE(/*state*/) {
      // Load enhanced mode from localStorage when app initializes
      // const savedEnhancedMode = localStorage.getItem('enhancedMode');
      //  tellme: I don't want enhanced mode stored locally - too disruptive
      // if (savedEnhancedMode !== null) {
      //   const parsedValue = JSON.parse(savedEnhancedMode);
      //   // console.log(`Initializing enhanced mode from localStorage: ${parsedValue}`);
      //   state.enhancedMode = parsedValue;
      // }
    },
    
    SET_META_TAGS(state, metaTags) {
      state.metaTags = metaTags;
      
      // Remove any existing meta tags we've added
      Array.from(document.querySelectorAll('[data-vue-router-controlled]')).forEach(el => {
        el.parentNode.removeChild(el);
      });
      
      // Add new meta tags
      metaTags.forEach(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-router-controlled', '');
        document.head.appendChild(tag);
      });
    },
    
    SET_OG_IMAGE(state, imageData) {
      // Remove any existing OG image tags we've controlled
      Array.from(document.querySelectorAll('meta[property^="og:image"][data-vue-router-controlled]')).forEach(el => {
        el.parentNode.removeChild(el);
      });
      
      // Create new OG image tags
      const { url, width, height, alt } = imageData;
      
      // Add main image URL
      const imageTag = document.createElement('meta');
      imageTag.setAttribute('property', 'og:image');
      imageTag.setAttribute('content', url);
      imageTag.setAttribute('data-vue-router-controlled', '');
      document.head.appendChild(imageTag);
      
      // Add optional width, height and alt if provided
      if (width) {
        const widthTag = document.createElement('meta');
        widthTag.setAttribute('property', 'og:image:width');
        widthTag.setAttribute('content', width.toString());
        widthTag.setAttribute('data-vue-router-controlled', '');
        document.head.appendChild(widthTag);
      }
      
      if (height) {
        const heightTag = document.createElement('meta');
        heightTag.setAttribute('property', 'og:image:height');
        heightTag.setAttribute('content', height.toString());
        heightTag.setAttribute('data-vue-router-controlled', '');
        document.head.appendChild(heightTag);
      }
      
      if (alt) {
        const altTag = document.createElement('meta');
        altTag.setAttribute('property', 'og:image:alt');
        altTag.setAttribute('content', alt);
        altTag.setAttribute('data-vue-router-controlled', '');
        document.head.appendChild(altTag);
      }
    }
  },
  
  actions: {
    setEnhancedMode({ commit }, enabled) {
      // console.log(`Action: setEnhancedMode(${enabled})`);
      commit('SET_ENHANCED_MODE', enabled);
    },
    
    initializeFromQuery({ dispatch }, query) {
      // console.log('Action: initializeFromQuery', query);
      // Check query parameters for enhanced mode
      if (query && query.mode === 'enhanced') {
        // console.log('Enhanced mode detected in query parameters');
        dispatch('setEnhancedMode', true);
      }
    },
    
    updateRouteMetadata({ commit, rootState }, { route, defaultTags = null }) {
      const nearestWithTitle = route.matched.slice().reverse().find(r => r.meta && r.meta.title);
      const nearestWithMeta = route.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
      
      let titleData = null;
      let metaTags = null;

      // console.log(`route`, route.name);

      // Process specific route types
      if (route.name === 'prints-v2-category' && route.params.category) {
        // For print categories (e.g., /prints/golden-horizons)
        const categoryTitle = slugToTitle(route.params.category);

        titleData = { 
          pageType: 'prints',
          printCategory: route.params.category 
        };
        // console.log(`categoryTitle: ${categoryTitle}`);
        
        metaTags = [
          {
            name: 'description',
            content: `Browse our ${categoryTitle} photo collection from Moss Beach, California.`
          },
          {
            property: 'og:title',
            content: `${categoryTitle} Photos | Moss Beach Life`
          },
          {
            property: 'og:description',
            content: `Browse our ${categoryTitle} photo collection from Moss Beach, California.`
          }
        ];
        
        // Try to find a category image for OG image
        const category = rootState.printsv2?.categories?.find(c => c.category === route.params.category);
        if (category?.photos?.length > 0) {
          // Use the first photo in the category as the OG image
          const photo = category.photos[0];
          if (photo?.imagePathSmall?.url) {
            commit('SET_OG_IMAGE', {
              url: photo.imagePathSmall.url,
              alt: `${categoryTitle} photo from Moss Beach Life`
            });
          }
        }
      } 
      else if (route.name === 'prints-v2-photo' && route.params.category && route.params.photoId) {
        // For individual prints (e.g., /prints/golden-horizons/sunset-1234)
        titleData = { 
          pageType: 'prints',
          printCategory: route.params.category,
          // We'll update the print title separately when it's loaded
        };
        
        metaTags = [
          {
            name: 'description',
            content: `View this photo from our ${slugToTitle(route.params.category)} collection at Moss Beach Life.`
          },
          {
            property: 'og:title',
            content: `Photo - ${slugToTitle(route.params.category)} | Moss Beach Life`
          },
          {
            property: 'og:description',
            content: `View this photo from our ${slugToTitle(route.params.category)} collection at Moss Beach Life.`
          }
        ];
        
        // Try to find the specific photo for OG image
        const category = rootState.printsv2?.categories?.find(c => c.category === route.params.category);
        if (category) {
          const photo = category.photos?.find(p => p.id === route.params.photoId);
          if (photo?.imagePathSmall?.url) {
            commit('SET_OG_IMAGE', {
              url: photo.imagePathSmall.url,
              alt: photo.title || `Photo from ${slugToTitle(route.params.category)} collection`
            });
          }
        }
      }
      else if (route.name === 'products-v2-type' && route.params.type) {
        // For product type pages (e.g., /store/apparel)
        titleData = {
          pageType: 'store',
          productType: route.params.type
        };
        
        const typeTitle = pluralizeType(route.params.type);
        
        metaTags = [
          {
            name: 'description',
            content: `Shop our ${typeTitle} collection featuring beautiful Moss Beach photography.`
          },
          {
            property: 'og:title',
            content: `${typeTitle} | Moss Beach Life`
          },
          {
            property: 'og:description',
            content: `Shop our ${typeTitle} collection featuring beautiful Moss Beach photography.`
          }
        ];
        
        // Try to find a product type image for OG image
        const productType = rootState.productsv2?.productTypes?.find(t => t.type === route.params.type);
        if (productType?.imageUrl) {
          // Use the product type image as OG image
          commit('SET_OG_IMAGE', {
            url: productType.imageUrl,
            alt: `${typeTitle} from Moss Beach Life`
          });
        }
      }
      else if (route.name === 'products-v2-detail' && route.params.type && route.params.group) {
        // For product detail pages
        titleData = {
          pageType: 'store',
          productType: route.params.type,
          productGroup: route.params.group
        };
        
        metaTags = [
          {
            name: 'description',
            content: `Shop our ${slugToTitle(route.params.group)} from the ${pluralizeType(route.params.type)} collection at Moss Beach Life.`
          },
          {
            property: 'og:title',
            content: `${pluralizeType(route.params.type)} - ${slugToTitle(route.params.group)} | Moss Beach Life`
          },
          {
            property: 'og:description',
            content: `Shop our ${slugToTitle(route.params.group)} from the ${pluralizeType(route.params.type)} collection at Moss Beach Life.`
          }
        ];
      }
      else if (route.name === 'prints-v2') {
        // Main prints page
        titleData = { pageType: 'prints' };
      }
      else if (route.name === 'products-v2') {
        // Main store page
        titleData = { pageType: 'store' };
      }
      else if (route.name === 'gallery') {
        // Gallery page
        titleData = { pageType: 'gallery' };
      }
      else if (route.name === 'cart') {
        // Cart page
        titleData = { pageType: 'cart' };
      }
      else if (route.name === 'orders') {
        // Orders page
        titleData = { pageType: 'orders' };
      }
      else if (route.name === 'contact') {
        // Contact page
        titleData = { pageType: 'contact' };
      }
      else if (route.name === 'about') {
        // About page
        titleData = { pageType: 'about' };
      }
      else if (route.name === 'home') {
        // Home page
        titleData = { pageType: 'home' };
      }
      else if (nearestWithTitle) {
        // Use route meta for title
        titleData = { pageTitle: nearestWithTitle.meta.title };
      }
      
      // Set title if we have one
      if (titleData) {
        commit('SET_TITLE_DATA', titleData);
      }
      
      // Set meta tags if we have any
      if (metaTags) {
        commit('SET_META_TAGS', metaTags);
      } else if (nearestWithMeta?.meta?.metaTags) {
        commit('SET_META_TAGS', nearestWithMeta.meta.metaTags);
      } else if (defaultTags) {
        commit('SET_META_TAGS', defaultTags);
      }
    },
    
    updateMetaInfo({ commit }, { title, description, image, metaTags }) {
      // Allow title to be either string or object
      if (typeof title === 'string') {
        commit('SET_TITLE_DATA', { pageTitle: title });
      } else if (typeof title === 'object') {
        commit('SET_TITLE_DATA', title);
      }
      
      // Build meta tags array
      let metaTagsArray = [];
      
      // If custom meta tags are provided, use those
      if (metaTags && Array.isArray(metaTags)) {
        metaTagsArray = metaTags;
      } 
      // Otherwise build standard meta tags
      else if (description) {
        metaTagsArray = [
          {
            name: 'description',
            content: description
          },
          {
            property: 'og:title',
            content: typeof title === 'string' ? title : formatTitle(title)
          },
          {
            property: 'og:description',
            content: description
          }
        ];
      }
      
      // Apply meta tags if we have any
      if (metaTagsArray.length > 0) {
        commit('SET_META_TAGS', metaTagsArray);
      }
      
      // Update OG image if provided
      if (image) {
        commit('SET_OG_IMAGE', image);
      }
    },

    // New action to update product group details when loaded
    updateProductDetails({ commit, state }, { groupDescription, groupImageUrl }) {
      if (state.titleData && state.titleData.pageType === 'store' && 
          state.titleData.productType && state.titleData.productGroup) {
        // No need to update title, but we can update meta tags with description
        if (groupDescription) {
          const metaTags = [
            {
              name: 'description',
              content: groupDescription
            },
            {
              property: 'og:title',
              content: formatTitle(state.titleData)
            },
            {
              property: 'og:description',
              content: groupDescription
            }
          ];
          
          commit('SET_META_TAGS', metaTags);
        }
        
        // Update image if provided
        if (groupImageUrl) {
          const productType = state.titleData.productType;
          const productGroup = state.titleData.productGroup;
          
          commit('SET_OG_IMAGE', {
            url: groupImageUrl,
            alt: `${slugToTitle(productGroup)} - ${pluralizeType(productType)}`
          });
        }
      }
    }
  }
}; 