export class CartItem {
    constructor(product, quantity = 1) {
        this.product = product;
        this.quantity = quantity;
    }
    get total() {
        return this.product.price * this.quantity;
    }
    increaseQuantity() {
        if (this.product.canIncrement) {
            this.quantity++;
        }
    }
    decreaseQuantity() {
        if (this.quantity > 0) {
            this.quantity--;
        }
    }

    static fromLocalStorage(local) {
        return new CartItem(
            new CartProduct(
                local.product.productId,
                local.product.priceId,
                local.product.name,
                local.product.price,
                local.product.imageUrl,
                local.product.productUrl,
                local.product.selectOption,
                local.product.specialMessage,
                local.product.inventory,
                local.product.features,
                local.product.type ? local.product.type : null,
                local.unitWeight ? local.unitWeight : 4,
                local.dimLength ? local.dimLength : 8,
                local.dimWidth ? local.dimWidth : 8,
                local.dimHeight ? local.dimHeight : 0.25
            ),
            local.quantity
        );
    }
}

export class CartProduct {
    constructor(
        productId,
        priceId,
        name,
        price,
        imageUrl,
        productUrl,
        selectOption,
        specialMessage,
        inventory = -1,
        features = [],
        type,
        unitWeight,
        dimLength,
        dimWidth,
        dimHeight
    ) {
        this.productId = productId;
        this.priceId = priceId;
        this.name = name;
        this.price = price;
        this.imageUrl = imageUrl;
        this.productUrl = productUrl;
        this.selectOption = selectOption;
        this.specialMessage = specialMessage;
        this.inventory = inventory;
        this.features = features;
        this.type = type;
        this.unitWeight = unitWeight;
        this.dimLength = dimLength;
        this.dimWidth = dimWidth;
        this.dimHeight = dimHeight;
    }

    get canIncrement() {
        return this.inventory < 0 || this.inventory > 0;
    }

    get isExternalUrl() {
        return this.productUrl?.toLowerCase().startsWith('http') ?? false;
    }

    get formattedFeatures() {
        if (!this.features || this.features.length === 0) {
            return null;
        }
        return this.features.join(', ');
    }
}