<template>
  <div v-if="showDebug" class="enhanced-mode-debug">
    <div class="enhanced-mode-debug-content">
      <div class="enhanced-mode-status">
        <strong>Enhanced Mode:</strong> 
        <span :class="{ 'enabled': isEnhancedMode, 'disabled': !isEnhancedMode }">
          {{ isEnhancedMode ? 'Enabled' : 'Disabled' }}
        </span>
      </div>
      <div class="enhanced-mode-actions">
        <button @click="toggleEnhancedMode" class="toggle-button">
          {{ isEnhancedMode ? 'Disable' : 'Enable' }} Enhanced Mode
        </button>
        <button @click="copyEnhancedUrl" class="copy-button">
          Copy Enhanced URL
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { addEnhancedMode } from '@/utils/enhanced';
import errorReporting from "@/errorReporting";

export default {
  name: 'EnhancedModeDebug',
  props: {
    // Allow external control of visibility
    show: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore();
    
    // Get enhanced mode state from store
    const isEnhancedMode = computed(() => store.getters['metadata/isEnhancedMode']);

    // Determine if we should show the debug panel
    const showDebug = computed(() => {
      // Always show if already in enhanced mode
      if (isEnhancedMode.value) return true;
      // Otherwise respect the prop
      return props.show;
    });
    
    // Toggle enhanced mode state
    const toggleEnhancedMode = () => {
      store.dispatch('metadata/setEnhancedMode', !isEnhancedMode.value);
    };
    
    // Copy enhanced URL to clipboard
    const copyEnhancedUrl = () => {
      const enhancedUrl = addEnhancedMode(window.location.href);
      navigator.clipboard.writeText(enhancedUrl).then(() => {
        alert('Enhanced URL copied to clipboard!');
      }).catch(error => {
        console.error('Could not copy URL: ', error);
        errorReporting.reportVueError(error, this, 'EnhancedModeDebug.copyEnhancedUrl')
        alert('Failed to copy URL. Please copy manually: ' + enhancedUrl);
      });
    };
    
    return {
      isEnhancedMode,
      showDebug,
      toggleEnhancedMode,
      copyEnhancedUrl
    };
  }
};
</script>

<style scoped>
.enhanced-mode-debug {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 5px;
  padding: 10px;
  z-index: 9999;
  font-size: 12px;
  max-width: 250px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.enhanced-mode-debug-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.enhanced-mode-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.enabled {
  color: #4CAF50;
  font-weight: bold;
}

.disabled {
  color: #F44336;
  font-weight: normal;
}

.enhanced-mode-actions {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
}

button {
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
}

.toggle-button {
  background-color: #2196F3;
  color: white;
}

.toggle-button:hover {
  background-color: #0b7dda;
}

.copy-button {
  background-color: #4CAF50;
  color: white;
}

.copy-button:hover {
  background-color: #45a049;
}
</style> 