<template>
  <div class="social-sharing">
    <h3>{{ title }}</h3>
    <div class="share-buttons">
      <button @click="shareOnFacebook" class="share-btn facebook" title="Share on Facebook">
        <i class="fa-brands fa-facebook"></i>
      </button>
      <button @click="shareOnTwitter" class="share-btn twitter" title="Share on Twitter">
        <i class="fa-brands fa-twitter"></i>
      </button>
      <button v-if="hasImage" @click="shareOnPinterest" class="share-btn pinterest" title="Pin on Pinterest">
        <i class="fa-brands fa-pinterest"></i>
      </button>
      <button @click="shareOnNextdoor" class="share-btn nextdoor" title="Share on Nextdoor">
        <i class="fa-solid fa-house-chimney"></i>
      </button>
      <button @click="shareViaEmail" class="share-btn email" title="Share via Email">
        <i class="fa-solid fa-envelope"></i>
      </button>
      <button @click="copyShareLink" class="share-btn copy">
        <i class="fa-solid fa-link"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import {getPrintShareUrl, getProductShareUrl} from "@/utils/url";
import errorReporting from "@/errorReporting";

const buildMessage = (platform, props, pageUrl) => {

  if (props.productType) {
    return `Check out this ${props.productType} "${props.productGroup}" at MossBeachLife: ${pageUrl}`
  } else if (props.printCategory) {
    return `Check out this ${props.printCategory} photo "${props.printTitle}" - " at MossBeachLife: ${pageUrl}`;
  } else {
    return `Check out ${props.title} at MossBeachLife: ${pageUrl}`;
  }
}

export default {
  props: {
    productType: {
      type: String,
      required: false
    },
    productGroup: {
      type: String,
      required: false
    },
    printCategory: {
      type: String,
      required: false
    },
    printId: {
      type: String,
      required: false
    },
    printTitle: {
      type: String,
      required: false
    },
    imageUrl: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'Share this'
    },
  },
  setup(props) {
    const copySuccess = ref(false);

    const hasImage = computed(() => !!props.imageUrl);

    const formatCategoryName = (category) => {
      return category
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };

    const getSocialShareUrl = () => {
      if (props.printCategory) {
        return getPrintShareUrl(props.printCategory, props.printId)
      } else {
        return getProductShareUrl(props.productType, props.productGroup)
      }

    };

    const shareOnFacebook = () => {
      const url = encodeURIComponent(getSocialShareUrl());
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
    };

    const shareOnTwitter = () => {
      const url = getSocialShareUrl();
      const text = buildMessage('twitter', props)
      window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`, '_blank');
    };

    const shareOnPinterest = () => {
      const url = getSocialShareUrl();
      const media = props.imageUrl;
      const text = buildMessage('pinterest', props, url)
      window.open(`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(media)}&description=${encodeURIComponent(text)}`, '_blank');
    };

    const shareOnNextdoor = () => {
      const url = getSocialShareUrl();
      const text = buildMessage('nextdoor', props, url)
      // const hashtag = ''
      // &hashtag=${hashtag}
      window.open(`https://nextdoor.com/sharekit/?source=${encodeURIComponent(url)}&body=${encodeURIComponent(text)}`, '_blank');
    };

    const shareViaEmail = () => {
      const url = getSocialShareUrl();
      const subject = `${props.itemTitle} - Moss Beach Life`;
      const text =  buildMessage('email', props, url)
      window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(text)}`;
    };

    const copyShareLink = () => {
      navigator.clipboard.writeText(getSocialShareUrl())
        .then(() => {
          copySuccess.value = true;
          setTimeout(() => {
            copySuccess.value = false;
          }, 2000);
        })
        .catch(error => {
          console.error('Failed to copy URL: ', error);
          errorReporting.reportVueError(error, this, 'SocialSharing.copySharedLink')
        });
    };

    return {
      hasImage,
      formatCategoryName,
      shareOnFacebook,
      shareOnTwitter,
      shareOnPinterest,
      shareOnNextdoor,
      shareViaEmail,
      copyShareLink,
      copySuccess
    };
  }
};
</script>

<style scoped>
.social-sharing {
  margin: 1.5rem 0;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.social-sharing h3 {
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
  font-weight: 500;
}

.share-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.share-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  transition: all 0.2s;
  text-decoration: none;
  font-size: 1.2rem;
}

.share-btn:hover {
  transform: translateY(-3px);
}

.share-btn.facebook {
  background-color: #3b5998;
}

.share-btn.twitter {
  background-color: #1da1f2;
}

.share-btn.pinterest {
  background-color: #e60023;
}

.share-btn.nextdoor {
  background-color: #00B53A;
}

.share-btn.email {
  background-color: #657786;
}

.share-btn.copy {
  background-color: #4a90e2;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .share-buttons {
    gap: 0.5rem;
  }
  
  .share-btn {
    width: 36px;
    height: 36px;
    font-size: 1rem;
  }
  
  .social-sharing {
    margin: 1rem 0;
    padding: 0;
  }
}
</style> 