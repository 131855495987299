import { CategoryV2 } from "@/models/PrintV2";
import axios from 'axios';
import errorReporting from "@/errorReporting";

export default {
    namespaced: true,
    state: {
        categories: [],
        currentPrint: null,
        selectedFormat: null,
        selectedSize: null,
        selectedFinish: null,
    },
    getters: {
        // Basic state getters
        categories: state => state.categories,
        availableFinishes: state => {
            if (!state.selectedFormat || !state.selectedSize) return [];
            return state.selectedFormat.getFinishes(state.selectedSize);
        },
        selectedProduct: state => {
            if (!state.selectedFormat || !state.selectedSize || !state.selectedFinish) return null;
            return state.selectedFormat.getProduct(state.selectedSize, state.selectedFinish);
        },
        recommendedProducts: state => {
            if (!state.currentPrint) return [];
            return state.currentPrint.getRecommendedProducts();
        },
    },
    mutations: {
        SET_CATEGORIES(state, categories) {
            state.categories = categories;
        },
    },
    actions: {
        async loadCategories({ commit, state }) {
            // Return cached categories if available
            if (state.categories.length > 0) {
                return state.categories;
            }

            const url = '/products/new_prints.json';
            try {
                const { data } = await axios.get(url);
                const categories = data.map(category => CategoryV2.fromApiResponse(category));
                commit('SET_CATEGORIES', categories);
                return categories;
            } catch (error) {
                commit('SET_CATEGORIES', []);
                console.error('Error loading prints:', error);
                errorReporting.reportApiError(error, {
                    url: url,
                    method: 'GET',
                    headers: {}
                })
                throw new Error('An error occurred while loading prints');
            }
        },
    }
} 