/**
 * Utilities for working with enhanced mode
 */

/**
 * Adds the enhanced mode flag to a URL
 * @param {string} url - The URL to add the flag to
 * @returns {string} - The URL with the enhanced mode flag added
 */
export const addEnhancedMode = (url) => {
  if (!url) return url;
  
  // Check if the URL already has query parameters
  const hasQuery = url.includes('?');
  
  // Add the mode=enhanced flag
  return `${url}${hasQuery ? '&' : '?'}mode=enhanced`;
};

/**
 * Adds enhanced mode to the current URL
 * @returns {string} - The current URL with enhanced mode added
 */
export const getCurrentUrlWithEnhancedMode = () => {
  return addEnhancedMode(window.location.href);
};

/**
 * Checks if a URL has the enhanced mode flag
 * @param {string} url - The URL to check
 * @returns {boolean} - True if the URL has the enhanced mode flag
 */
export const hasEnhancedMode = (url) => {
  if (!url) return false;
  
  // Parse the URL
  try {
    const urlObj = new URL(url);
    return urlObj.searchParams.get('mode') === 'enhanced';
  } catch (e) {
    // If the URL is invalid, just check for the query string
    return url.includes('mode=enhanced');
  }
};

/**
 * Removes the enhanced mode flag from a URL
 * @param {string} url - The URL to remove the flag from
 * @returns {string} - The URL without the enhanced mode flag
 */
export const removeEnhancedMode = (url) => {
  if (!url) return url;
  
  try {
    const urlObj = new URL(url);
    urlObj.searchParams.delete('mode');
    return urlObj.toString();
  } catch (e) {
    // Simple string replacement for invalid URLs
    return url.replace(/[?&]mode=enhanced(&|$)/, (match, p1) => {
      return p1 === '&' ? '&' : '';
    });
  }
}; 