import {ProductGroupV2} from "@/models/ProductV2";
import errorReporting from "@/errorReporting";

export default {
    namespaced: true,
    state: {
        loaded: false,
        products: [],
    },
    getters: {
        products: (state) => state.products,
        loaded: (state) => state.loaded,
    },
    mutations: {
        products(state, products) {
            state.products = products
        },
        loaded(state, loaded) {
            state.loaded = loaded
        }
    },
    actions: {
        async loadLocalProducts(context) {
            try {
                const response = await fetch('/products/new_products.json')
                // if (response.status === 200) {
                const data = await response.json()

                const products = data.map((product) => {
                    return ProductGroupV2.fromApiResponse(product);
                })

                await context.commit("products", products)
                await context.commit("loaded", true)
            } catch (error) {
                console.error('Error loading products:', error)
                errorReporting.reportApiError(error, {
                    url: '/products/new_products.json',
                    method: 'GET',
                    headers: {}
                })
                await context.commit("products", [])
                // throw error
            }
        },
        async loadProducts(context) {
            if (!context.getters["loaded"]) {
                await context.dispatch("loadLocalProducts")
            }
        }
    },
    modules: {
    }
}
