<template>
  <div class="print-locations" v-if="location">
    <div class="locations-container" :class="{ 'has-map': hasMapData && mapLink }">
      <!-- Single location display -->
<!--      <div class="locations-list">-->
        <div class="location-card">
          <div class="location-header">
            <h4>{{ location.title }}</h4>
          </div>
          <p v-if="location.description" class="location-description">
            <span v-for="(line, index) in location.description.split('\\n')" :key="index">
              {{ line }}
              <br v-if="index < location.description.split('\\n').length - 1">
            </span>
          </p>
          <a v-if="location.url" 
             :href="location.url" 
             target="_blank" 
             class="location-link"
             @click.stop>
             Visit Website ↗
          </a>
          <p v-if="location.address" class="location-address">
            {{ location.getFormattedAddress() }}
          </p>
          <a v-if="mapLink" 
             :href="mapLink" 
             target="_blank" 
             class="maps-link"
             @click.stop>
            Open in Google Maps ↗
          </a>
<!--        </div>-->
      </div>

      <!-- Map container -->
      <div class="map-container" ref="mapContainer" v-if="hasMapData && mapLink">
        <!-- Google Maps will be mounted here -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch, onUnmounted } from 'vue';

// Create a global callback namespace 
// (This helps prevent multiple components from overwriting each other's callbacks)
if (!window.mossBeachMapsCallbacks) {
  window.mossBeachMapsCallbacks = {};
}

export default {
  name: 'PrintLocation',
  
  props: {
    location: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const mapContainer = ref(null);
    const map = ref(null);
    const markers = ref([]);
    let mapsScriptLoaded = ref(false);

    // Create a unique ID for this component instance
    const componentId = `maps_component_${Date.now()}_${Math.floor(Math.random() * 1000)}`;

    // Check if we have map data
    const hasMapData = computed(() => {
      if (!props.location) return false;
      
      // Check for coordinates with valid lat/lng values
      const hasCoordinates = props.location.coordinates && 
                              props.location.coordinates.lat && 
                              props.location.coordinates.lng;
      
      // Check for valid placeId
      const hasPlaceId = !!props.location.placeId;
      
      // Check for address with actual content
      const hasAddress = props.location.address && 
                         props.location.getFormattedAddress().length > 3;
      
      return hasCoordinates || hasPlaceId || hasAddress;
    });

    // Get Google Maps link only if we have valid location data
    const mapLink = computed(() => {
      if (!props.location) return '';
      
      const url = props.location.getGoogleMapsUrl();
      // Only return the URL if it's not empty and we have actual location data
      return url && hasMapData.value ? url : '';
    });

    // Create map instance and add marker
    const createMap = () => {
      if (!mapContainer.value || !hasMapData.value || !window.google?.maps) return;
      
      map.value = new window.google.maps.Map(mapContainer.value, {
        zoom: 13,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      });

      updateMarker();
    };

    // A function to load the Google Maps API
    const loadGoogleMapsScript = () => {
      // Skip if script is already in the process of loading
      const existingScript = document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]');
      if (existingScript) {
        if (!window.google?.maps) {
          // Script is loading but not ready - wait for it
          const checkGoogleMaps = setInterval(() => {
            if (window.google?.maps) {
              clearInterval(checkGoogleMaps);
              createMap();
            }
          }, 100);
        }
        return;
      }

      // Register our callback
      window.initGoogleMapsCallback = () => {
        mapsScriptLoaded.value = true;
        createMap();
        
        // Execute any other callbacks that might be waiting
        if (window.mossBeachMapsCallbacks) {
          Object.values(window.mossBeachMapsCallbacks).forEach(callback => {
            if (typeof callback === 'function') {
              callback();
            }
          });
        }
      };
      
      // Create script element
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&callback=initGoogleMapsCallback`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    };

    // Initialize Google Maps
    const initMap = () => {
      if (!hasMapData.value || !mapContainer.value) return;

      // If Google Maps is already loaded, create the map right away
      if (window.google?.maps) {
        createMap();
        return;
      }
      
      // Otherwise, load the script first
      loadGoogleMapsScript();
    };

    // Update marker on the map
    const updateMarker = () => {
      if (!map.value || !props.location?.coordinates?.lat || !props.location?.coordinates?.lng) return;

      // Clear existing markers
      markers.value.forEach(marker => marker.setMap(null));
      markers.value = [];

      const position = {
        lat: props.location.coordinates.lat,
        lng: props.location.coordinates.lng
      };

      const marker = new window.google.maps.Marker({
        position,
        map: map.value,
        title: props.location.title,
        animation: window.google.maps.Animation.DROP
      });

      markers.value.push(marker);
      map.value.setCenter(position);
    };

    // Watch for changes in location
    watch(() => props.location, () => {
      if (map.value) {
        updateMarker();
      }
    }, { deep: true });

    // Initialize map when component is mounted
    onMounted(() => {
      // Register this component's callback
      window.mossBeachMapsCallbacks[componentId] = createMap;
      initMap();
    });

    // Clean up on component unmount
    onUnmounted(() => {
      // Remove this component's callback
      if (window.mossBeachMapsCallbacks && window.mossBeachMapsCallbacks[componentId]) {
        delete window.mossBeachMapsCallbacks[componentId];
      }
      
      // Clean up map instance
      if (map.value) {
        // Clear markers
        markers.value.forEach(marker => marker.setMap(null));
        markers.value = [];
        // No need to explicitly destroy the map object, it will be garbage collected
        map.value = null;
      }
    });

    return {
      mapContainer,
      hasMapData,
      mapLink
    };
  }
};
</script>

<style scoped>
.print-locations {
  height: 100%;
}

.print-locations h3 {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #333;
}

.locations-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  height: 400px;
  max-width: 600px;
}

.locations-container.has-map {
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  max-width: none;
}

.locations-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  /* Add styles for the scrollbar */
  scrollbar-width: thin;
  scrollbar-color: #4a90e2 #f0f0f0;
  padding-right: 0.5rem;
}

.locations-list::-webkit-scrollbar {
  width: 8px;
}

.locations-list::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 4px;
}

.locations-list::-webkit-scrollbar-thumb {
  background-color: #4a90e2;
  border-radius: 4px;
}

.location-card {
  padding: 1rem;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: all 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;
}

.location-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.location-header {
  margin-bottom: 0.5rem;
}

.location-header h4 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.location-link {
  color: #4a90e2;
  text-decoration: none;
  font-size: 0.9rem;
  display: block;
  margin-bottom: 0.5rem;
}

.location-description {
  margin: 0.5rem 0;
  color: #666;
  text-align: left;
  white-space: pre-line;
  flex-grow: 1;
}

.location-address {
  margin: 0.5rem 0;
  color: #444;
  font-style: italic;
  text-align: left;
}

.maps-link {
  display: inline-block;
  margin-top: 0.5rem;
  color: #4a90e2;
  text-decoration: none;
  font-size: 0.9rem;
}

.map-container {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ddd;
}

@media (max-width: 768px) {
  .locations-container {
    grid-template-columns: 1fr;
    height: auto;
    gap: 1rem;
  }

  .locations-list {
    height: auto;
  }

  .location-card {
    height: auto;
    min-height: 200px;
  }

  .map-container {
    height: 300px;
    order: -1;
  }
}
</style> 