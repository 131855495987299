<template>
  <div class="product-detail">
    <!-- Breadcrumb Navigation -->
    <div class="breadcrumb">
      <router-link :to="{ name: 'products-v2' }">All Types</router-link>
      <span> > </span>
      <router-link 
        :to="{ name: 'products-v2-type', params: { type: $route.params.type }}"
      >
        {{ formatTypeName($route.params.type) }}
      </router-link>
      <span> > </span>
      <span>{{ group?.group ? formatGroupName(group.group) : 'Loading...' }}</span>
    </div>

    <div v-if="loading" class="loading">
      Loading product details...
    </div>
    <div v-else-if="error" class="error">
      {{ error }}
      <button @click="loadProducts" class="retry-button">Retry</button>
    </div>
    <div v-else-if="!group" class="error">
      Product group not found.
    </div>
    <div v-else class="content">
      <!-- Main Content -->
      <div class="main-section">
        <!-- Group Image -->
        <div class="image-container">
          <img 
            v-if="group.groupImageUrl"
            :src="group.groupImageUrl" 
            :alt="group.group"
            class="main-image"
          />
        </div>

        <!-- Group Info -->
        <div class="info-section">
          <div class="group-header" @click="toggleDescription">
            <h1>{{ formatGroupName(group.group) }}{{ areAllProductsSoldOut ? ' (sold out)' : '' }}</h1>
            <span class="toggle-icon">{{ isDescriptionOpen ? '▼' : '▶' }}</span>
          </div>
          <div v-if="group.groupDescription" 
               class="description-container"
               :class="{ 'open': isDescriptionOpen }">
            <p class="description">
              {{ group.groupDescription }}
            </p>
          </div>

          <!-- Social Sharing -->
          <social-sharing 
            v-if="enhanced"
            :product-type="$route.params.type"
            :product-group="group.group"
            :image-url="group.groupImageUrl"
            title="Share this Product"
          />

          <!-- Product Photos Gallery -->
          <div v-if="group.images?.length" class="gallery-section">
            <div class="gallery-header" @click="toggleImages">
              <h2>Product Photos</h2>
              <span class="toggle-icon">{{ isImagesOpen ? '▼' : '▶' }}</span>
            </div>
            <div v-if="isImagesOpen" class="product-photos-container">
              <button 
                class="nav-button prev" 
                @click="scrollPhotos('left')"
                :disabled="atStart"
                v-show="showNavButtons"
              >❮</button>
              <div class="photos-slider" ref="photosSlider">
                <div
                  v-for="photo in group.images"
                  :key="photo.url"
                  class="photo-slide"
                  @click="openImageModal(photo)"
                >
                  <img 
                    :src="photo.url" 
                    :alt="'Product photo'"
                    :style="{
                      aspectRatio: `${photo.width} / ${photo.height}`,
                      objectFit: 'contain'
                    }"
                  />
                </div>
              </div>
              <button 
                class="nav-button next" 
                @click="scrollPhotos('right')"
                :disabled="atEnd"
                v-show="showNavButtons"
              >❯</button>
            </div>
          </div>

          <!-- Photos Gallery -->
          <div v-if="group.photos?.length" class="gallery-section">
            <div class="gallery-header" @click="toggleGallery">
              <h2>Featured Photos</h2>
              <span class="toggle-icon">{{ isGalleryOpen ? '▼' : '▶' }}</span>
            </div>
            <div v-if="isGalleryOpen" class="photos-grid">
              <div
                v-for="photo in group.photos"
                :key="photo.id"
                class="photo-card"
                @click="openPhotoModal(photo)"
              >
                <img 
                  :src="photo.imagePathSmall.url" 
                  :alt="photo.title"
                />
              </div>
            </div>
          </div>

          <!-- Products Section with Select Options -->
          <div v-if="group.products?.length" class="products-section">
            <h2>Available {{ group.selectName }}</h2>
            <p class="instructions">Click on a product to view options, then select an option to add it to your cart.</p>
            <p v-if="hasRecommendedOptions" class="option-legend">
              <span class="recommended-star">★</span> = Recommended option
            </p>
            
            <div class="product-list">
              <div 
                v-for="product in group.products" 
                :key="product.productId"
                class="product-card"
                :data-product-id="product.productId"
              >
                <div class="product-header" 
                     @click="toggleProduct(product.productId)"
                     :class="{ 'sold-out': !isAvailable(product) }">
                  <div class="product-title">
                    <h3>{{ product.name }}{{ !isAvailable(product) ? ' (sold out)' : '' }}</h3>
                    <span class="toggle-icon">{{ isProductOpen(product.productId) ? '▼' : '▶' }}</span>
                  </div>
                  <p v-if="product.description" class="product-description">{{ product.description }}</p>
                </div>

                <div v-if="isProductOpen(product.productId)" class="product-content">
                  <!-- Features Section -->
                  <div class="feature-descriptions" v-if="product.features?.length">
                    <h4 class="features-title">Features:</h4>
                    <div class="feature-details">
                      <div v-for="(feature, index) in product.features" :key="index" class="feature-detail">
                        {{ feature }}
                      </div>
                    </div>
                  </div>

                  <!-- Special Message Section -->
                  <div class="special-message" v-if="product.specialMessage">
                    <h4 class="special-message-title">Special Note:</h4>
                    <p class="special-message-text">{{ product.specialMessage }}</p>
                  </div>

                  <!-- Select Options -->
                  <div v-if="product.selectOptions?.length" class="options-section">
                    <div class="options-grid">
                      <div
                        v-for="option in product.selectOptions"
                        :key="option.value"
                        class="option-item"
                        :class="{ 
                          selected: isSelected(product, option),
                          recommended: option.recommended,
                          'sold-out': !isAvailable(product)
                        }"
                        @click.stop="isAvailable(product) ? selectOption(product, option) : null"
                      >
                        <div class="option-info">
                          <div class="option-name">{{ option.label }}</div>
                          <div v-if="isSelected(product, option)" class="selected-indicator">✓ Selected</div>
                        </div>
                        <div class="option-actions">
                          <div class="option-price">{{ formatPrice(option.price) }}</div>
                          <button
                            class="quick-add-btn"
                            @click.stop="quickAddToCart(product, option)"
                            :disabled="!isAvailable(product)"
                          >
                            {{ isAvailable(product) ? 'Buy Now' : 'Sold Out' }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Add to Cart Section -->
            <div v-if="selectedOption" class="cart-section">
              <div class="selected-summary">
                <h3>Selected Option</h3>
                <p>{{ selectedOption.product.name }} - {{ selectedOption.option.label }}</p>
                <p class="price">{{ formatPrice(selectedOption.option.price) }} each</p>
              </div>
              <div class="quantity-selector">
                <button 
                  class="quantity-btn" 
                  @click="quantity > 1 ? quantity-- : null"
                  :disabled="quantity <= 1"
                >-</button>
                <span class="quantity">{{ quantity }}</span>
                <button 
                  class="quantity-btn" 
                  @click="quantity++"
                >+</button>
              </div>
              <div class="total-section">
                <span class="total-label">Total:</span>
                <span class="total-price">{{ totalPrice }}</span>
              </div>
              <button 
                class="add-to-cart-btn" 
                @click="addToCart"
                :disabled="!isAvailable(selectedOption.product)"
              >
                {{ isAvailable(selectedOption.product) ? 'Add to Cart' : 'Out of Stock' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Photo Modal -->
    <div v-if="selectedPhoto" 
         class="photo-modal" 
         :class="{ active: isModalOpen }"
         @click="closePhotoModal">
      <div class="modal-content" @click.stop>
        <button 
          v-if="canNavigatePhotos && currentPhotoList?.length > 1"
          class="modal-nav prev" 
          @click.stop="navigatePhoto('prev')"
          :disabled="currentPhotoIndex <= 0"
        >❮</button>
        <img 
          :src="selectedPhoto.imagePathLarge?.url || selectedPhoto.imagePathSmall.url" 
          :alt="selectedPhoto.title" 
        />
        <button 
          v-if="canNavigatePhotos && currentPhotoList?.length > 1"
          class="modal-nav next" 
          @click.stop="navigatePhoto('next')"
          :disabled="currentPhotoIndex >= (currentPhotoList?.length || 0) - 1"
        >❯</button>
        <h3>{{ selectedPhoto.title }}</h3>
        <button class="close-button" @click="closePhotoModal">&times;</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch, onBeforeUnmount, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { pluralizeType } from '@/store/metadata'
import { CartItem } from '@/models/CartItem'
import Swal from 'sweetalert2'
import { getProductShareUrl, getProductDirectUrl } from '@/utils/url'
import SocialSharing from '@/components/social/SocialSharing.vue'
import errorReporting from "@/errorReporting";

export default {
  name: 'ProductDetailV2',
  components: {
    SocialSharing
  },

  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const loading = ref(true)
    const error = ref(null)
    const selectedPhoto = ref(null)
    const isModalOpen = ref(false)
    const quantity = ref(1)
    const selectedOption = ref(null)
    const openProducts = ref(new Set())
    const isGalleryOpen = ref(false)
    const isDescriptionOpen = ref(true)
    const isImagesOpen = ref(false)
    const photosSlider = ref(null)
    const showNavButtons = ref(false)
    const atStart = ref(true)
    const atEnd = ref(false)
    const currentPhotoList = ref([])
    const currentPhotoIndex = ref(0)
    const canNavigatePhotos = ref(false)

    const enhanced = computed(() => store.getters["metadata/isEnhancedMode"]);

    const group = computed(() => {
      if (!store.state.productsv2.productTypes) return null
      const decodedType = decodeURIComponent(route.params.type)
      const decodedGroup = decodeURIComponent(route.params.group)
      const type = store.state.productsv2.productTypes.find(
        t => t.type === decodedType
      )
      return type?.productGroups?.find(g => g.group === decodedGroup)
    })

    const hasRecommendedOptions = computed(() => {
      return group.value?.products?.some(product =>
        product.selectOptions?.some(option => option.recommended)
      )
    })

    const totalPrice = computed(() => {
      if (!selectedOption.value) return ''
      const total = selectedOption.value.option.price * quantity.value
      return formatPrice(total)
    })

    const areAllProductsSoldOut = computed(() => {
      if (!group.value?.products?.length) return false;
      return group.value.products.every(product => !isAvailable(product));
    });

    const loadProducts = async () => {
      loading.value = true;
      error.value = null;
      
      try {
        await store.dispatch('productsv2/loadProducts');
        
        // Decode URL parameters for better display
        const decodedType = decodeURIComponent(route.params.type);
        const decodedGroup = decodeURIComponent(route.params.group);
        
        // Update product information using the structured approach
        // The title will be formatted in the store, based on the product type and group
        await store.dispatch('metadata/updateMetaInfo', { 
          // Send structured title data instead of formatted string
          title: {
            pageType: 'store',
            productType: decodedType,
            productGroup: decodedGroup
          },
          description: group.value?.groupDescription || `Shop our ${formatGroupName(decodedGroup)} from the ${formatTypeName(decodedType)} collection at Moss Beach Life.`,
          image: group.value?.groupImageUrl ? {
            url: group.value.groupImageUrl,
            alt: `${formatGroupName(decodedGroup)} - ${formatTypeName(decodedType)}`
          } : null
        });
        
        // If we have additional group details, update them separately
        if (group.value?.groupDescription || group.value?.groupImageUrl) {
          await store.dispatch('metadata/updateProductDetails', {
            groupDescription: group.value.groupDescription,
            groupImageUrl: group.value.groupImageUrl
          });
        }
      } catch (error) {
        console.error('Error loading products:', error);
        errorReporting.reportVueError(error, this, 'ProductDetailV2.loadProducts')
        error.value = 'An error occurred while loading products. Please try again.';
      } finally {
        loading.value = false;
      }
    };

    const formatGroupName = (group) => {
      return group
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }

    const formatTypeName = (type) => {
      return pluralizeType(type);
    }

    const formatPrice = (price) => {
      return `$${(price / 100).toFixed(2)}`
    }

    const isAvailable = (product) => {
      return product.inventory === -1 || product.inventory > 0
    }

    const toggleProduct = (productId) => {
      if (openProducts.value.has(productId)) {
        openProducts.value.delete(productId)
      } else {
        openProducts.value.add(productId)
      }
    }

    const isProductOpen = (productId) => {
      return openProducts.value.has(productId)
    }

    const selectOption = (product, option) => {
      selectedOption.value = {
        product,
        option
      }
    }

    const isSelected = (product, option) => {
      return selectedOption.value?.product === product &&
             selectedOption.value?.option === option
    }

    const addToCart = async () => {
      if (!selectedOption.value) return

      try {
        const { product, option } = selectedOption.value
        const productUrl = router.resolve({
          name: 'products-v2-detail',
          params: {
            type: route.params.type,
            group: route.params.group
          },
          query: {
            option: option.label
          }
        }).fullPath

        const cartItem = new CartItem(
            product.toCartProduct(productUrl),
            quantity.value
        )

        await store.dispatch('cart/addToCartNew', cartItem)

        // Show success alert with options
        const result = await Swal.fire({
          title: 'Added to Cart!',
          text: `${quantity.value} x ${product.name} - ${option.label}`,
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#4a90e2',
          cancelButtonColor: '#6c757d',
          confirmButtonText: 'Go to Cart',
          cancelButtonText: 'Continue Shopping'
        })

        if (result.isConfirmed) {
          router.push({ name: 'cart' })
        }
      } catch (error) {
        if (error.errorType !== 'AlreadyInCart') {
          console.error('Error adding to cart:', error)
          errorReporting.reportVueError(error, this, 'ProductDetailV2.addToCart')
          await Swal.fire({
            title: 'Error',
            text: 'Failed to add item to cart. Please try again.',
            icon: 'error',
            confirmButtonColor: '#4a90e2'
          })
        } else {
          await Swal.fire({
            title: 'Already in Cart',
            text: 'This item is already in your cart.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#4a90e2',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Go to Cart',
            cancelButtonText: 'Continue Shopping'
          }).then((result) => {
            if (result.isConfirmed) {
              router.push({ name: 'cart' })
            }
          })
        }
      }
    }

    const quickAddToCart = async (product, option) => {
      try {
        const productUrl = router.resolve({
          name: 'products-v2-detail',
          params: {
            type: route.params.type,
            group: route.params.group
          },
          query: {
            option: option.label
          }
        }).fullPath
        const cartItem = new CartItem(
            product.toCartProduct(productUrl),
            1
        )

        await store.dispatch('cart/addToCartNew', cartItem)
        // Navigate to cart page after successful add
        router.push({ name: 'cart' })
      } catch (error) {
        if (error.errorType !== 'AlreadyInCart') {
          console.error('Error adding to cart:', error)
          errorReporting.reportVueError(error, this, 'ProductDetailV2.quickAddToCart')
        } else {
          // If item is already in cart, still navigate to cart
          router.push({ name: 'cart' })
        }
      }
    }

    const openPhotoModal = (photo) => {
      // Set up navigation context for featured photos first
      if (group.value?.photos?.length) {
        currentPhotoList.value = group.value.photos
        currentPhotoIndex.value = currentPhotoList.value.findIndex(p => p.id === photo.id)
        canNavigatePhotos.value = currentPhotoList.value.length > 1
      }
      // Then set the selected photo and open modal
      selectedPhoto.value = photo
      isModalOpen.value = true
      document.body.style.overflow = 'hidden'
    }

    const openImageModal = (photo) => {
      // Set up navigation context for product photos first
      if (group.value?.images?.length) {
        currentPhotoList.value = group.value.images.map(p => ({
          imagePathLarge: { url: p.url },
          imagePathSmall: { url: p.url },
          title: p.title || 'Product Photo'
        }));
        currentPhotoIndex.value = group.value.images.findIndex(p => p.url === photo.url);
        canNavigatePhotos.value = currentPhotoList.value.length > 1;
      }
      // Then set the selected photo and open modal
      selectedPhoto.value = {
        imagePathLarge: { url: photo.url },
        imagePathSmall: { url: photo.url },
        title: photo.title || 'Product Photo'
      };
      isModalOpen.value = true;
      document.body.style.overflow = 'hidden';
    };

    const navigatePhoto = (direction) => {
      if (!currentPhotoList.value?.length) return;

      const newIndex = direction === 'prev' ?
        currentPhotoIndex.value - 1 :
        currentPhotoIndex.value + 1;

      if (newIndex >= 0 && newIndex < currentPhotoList.value.length) {
        currentPhotoIndex.value = newIndex;
        selectedPhoto.value = currentPhotoList.value[newIndex];
      }
    };

    const closePhotoModal = () => {
      selectedPhoto.value = null
      isModalOpen.value = false
      document.body.style.overflow = ''
      currentPhotoList.value = []
      currentPhotoIndex.value = 0
      canNavigatePhotos.value = false
    }

    const toggleGallery = () => {
      isGalleryOpen.value = !isGalleryOpen.value
    }

    const toggleDescription = () => {
      isDescriptionOpen.value = !isDescriptionOpen.value
    }

    const toggleImages = () => {
      isImagesOpen.value = !isImagesOpen.value;
    };

    const updateNavButtonsState = () => {
      if (!photosSlider.value) return;

      const slider = photosSlider.value;
      atStart.value = slider.scrollLeft <= 0;
      atEnd.value = slider.scrollLeft + slider.clientWidth >= slider.scrollWidth;
      showNavButtons.value = slider.scrollWidth > slider.clientWidth;
    };

    // Watch for changes in the product photos
    watch(() => group.value?.images, () => {
      if (group.value?.images?.length) {
        nextTick(() => {
          updateNavButtonsState();
        });
      }
    });

    // Watch for changes in isImagesOpen
    watch(isImagesOpen, (newValue) => {
      if (newValue) {
        nextTick(() => {
          updateNavButtonsState();
        });
      }
    });

    const scrollPhotos = (direction) => {
      if (!photosSlider.value) return;

      const slider = photosSlider.value;
      const scrollAmount = slider.clientWidth * 0.8; // Scroll 80% of container width

      slider.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });

      // Update nav buttons after scrolling
      setTimeout(updateNavButtonsState, 100);
    };

    // For getting product share URL with UTM params
    const getSocialShareUrl = () => {
      const baseUrl = window.location.origin;
      const path = getProductShareUrl(route.params.productId);
      return `${baseUrl}${path}`;
    };

    // For direct sharing (copy link)
    const getDirectShareUrl = () => {
      const baseUrl = window.location.origin;
      const path = getProductDirectUrl(route.params.productId);
      return `${baseUrl}${path}`;
    };

    // Lifecycle hooks
    onMounted(() => {
      loadProducts();
      nextTick(() => {
        if (photosSlider.value) {
          updateNavButtonsState();
          photosSlider.value.addEventListener('scroll', updateNavButtonsState);
          window.addEventListener('resize', updateNavButtonsState);
        }
      });
    });

    // Watch for route changes including query params
    watch(
      () => [route.params.type, route.params.group, route.query],
      () => {
        loadProducts()
      }
    )

    // Clean up on component unmount
    onBeforeUnmount(() => {
      if (photosSlider.value) {
        photosSlider.value.removeEventListener('scroll', updateNavButtonsState)
        window.removeEventListener('resize', updateNavButtonsState)
      }
      if (isModalOpen.value) {
        document.body.style.overflow = ''
      }
    })

    const productType = computed(() => {
      return route.params.type
    })

    return {
      enhanced,
      loading,
      error,
      group,
      selectedPhoto,
      isModalOpen,
      quantity,
      selectedOption,
      hasRecommendedOptions,
      totalPrice,
      formatGroupName,
      formatTypeName,
      formatPrice,
      isAvailable,
      toggleProduct,
      isProductOpen,
      selectOption,
      isSelected,
      addToCart,
      quickAddToCart,
      openPhotoModal,
      closePhotoModal,
      loadProducts,
      isGalleryOpen,
      toggleGallery,
      isDescriptionOpen,
      toggleDescription,
      areAllProductsSoldOut,
      isImagesOpen,
      toggleImages,
      photosSlider,
      scrollPhotos,
      showNavButtons,
      atStart,
      atEnd,
      canNavigatePhotos,
      currentPhotoIndex,
      navigatePhoto,
      currentPhotoList,
      productType,
      openImageModal,
      getSocialShareUrl,
      getDirectShareUrl,
    }
  }
}
</script>

<style scoped>
.product-detail {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  min-height: 200px;
  transition: opacity 0.3s ease;
}

.breadcrumb {
  margin-bottom: 2rem;
  font-size: 0.9rem;
}

.breadcrumb a {
  color: #4a90e2;
  text-decoration: none;
}

.breadcrumb span {
  margin: 0 0.5rem;
}

.loading,
.error {
  position: relative;
  text-align: center;
  padding: 2rem;
  color: #666;
  font-size: 1.2rem;
  background: #f8f9fa;
  border-radius: 8px;
  margin: 2rem 0;
}

.retry-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.main-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.image-container {
  width: 75%;
  margin: 0 auto;
  padding-top: 42.19%; /* 75% of 56.25% to maintain 16:9 aspect ratio */
  position: relative;
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: white;
}

.info-section {
  padding-top: 0;
  text-align: start;
}

.group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0;
}

.group-header h1 {
  margin: 0;
  font-size: 2rem;
  color: #333;
}

.description-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.description-container.open {
  max-height: 2000px; /* Increased to accommodate longer text */
  transition: max-height 0.8s ease-in;
}

.description {
  color: #666;
  white-space: pre-line;
  margin: 1rem 0;
  padding: 0.5rem 0;
  line-height: 1.8; /* Increased line height for better readability */
  overflow-wrap: break-word;
  font-size: 1.1rem; /* Slightly larger font size */
  letter-spacing: 0.01em; /* Slight letter spacing for better readability */
}

.description p {
  margin: 1.5em 0; /* Add more spacing between paragraphs */
}

.products-section {
  margin-top: 2rem;
}

.option-legend {
  margin-bottom: 1rem;
  color: #666;
}

.recommended-star {
  color: #f0ad4e;
  margin-right: 0.5rem;
}

.product-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background: white;
}

.product-header {
  padding: 1rem;
  cursor: pointer;
  background: #f8f9fa;
  transition: background-color 0.2s;
}

.product-header.sold-out {
  background: #ffe8e8;
}

.product-header:hover {
  background: #e9ecef;
}

.product-header.sold-out:hover {
  background: #ffd8d8;
}

.product-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-title h3 {
  margin: 0;
}

.toggle-icon {
  font-size: 0.8rem;
  color: #666;
}

.product-description {
  margin: 0.5rem 0 0;
  color: #666;
}

.product-content {
  padding: 1rem;
}

.feature-descriptions {
  margin-bottom: 1.5rem;
}

.features-title {
  margin: 0 0 1rem 0;
  font-size: 1.1rem;
  color: #333;
}

.feature-details {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.feature-detail {
  color: #4a5568;
  background: #edf2f7;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.95rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  border: 1px solid #e2e8f0;
}

.options-grid {
  display: grid;
  gap: 0.5rem;
}

.option-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  background: white;
}

.option-item:hover {
  border-color: #4a90e2;
  background: #f8f9fa;
}

.option-item.selected {
  border-color: #4a90e2;
  background: #e3f2fd;
  border-width: 2px;
}

.option-item.recommended {
  border-color: #f0ad4e;
}

.option-item.recommended::after {
  content: '★';
  color: #f0ad4e;
  margin-left: 0.5rem;
}

.option-item.sold-out {
  opacity: 0.8;
  cursor: not-allowed;
  border-color: #ffcdd2;
}

.option-item.sold-out:hover {
  border-color: #ffcdd2;
  background: #fff5f5;
}

.option-item.sold-out .option-price {
  color: #666;
}

.option-info {
  flex: 1;
}

.option-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.option-name {
  font-size: 1.1rem;
  font-weight: 500;
}

.quick-add-btn {
  padding: 0.5rem 1rem;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.quick-add-btn:hover {
  background: #357abd;
}

.quick-add-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.option-price {
  font-weight: bold;
  color: #4a90e2;
}

.cart-section {
  margin-top: 2rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f8f9fa;
}

.selected-summary {
  margin-bottom: 1rem;
}

.selected-summary h3 {
  margin: 0 0 0.5rem 0;
}

.quantity-selector {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
}

.quantity-btn {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quantity {
  font-size: 1.2rem;
  min-width: 30px;
  text-align: center;
}

.total-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  font-size: 1.2rem;
}

.total-price {
  font-weight: bold;
  color: #4a90e2;
}

.add-to-cart-btn {
  width: 100%;
  padding: 1rem;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.add-to-cart-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.gallery-section {
  margin: 2rem 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.gallery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.2s;
}

.gallery-header:hover {
  background: #e9ecef;
}

.gallery-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.photos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
  background: white;
}

.photo-card {
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.photo-card:hover {
  transform: scale(1.05);
}

.photo-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.photo-modal.active {
  opacity: 1;
  visibility: visible;
}

.modal-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  background: white;
  padding: 1rem;
  border-radius: 8px;
}

.modal-content img {
  max-width: 100%;
  max-height: calc(90vh - 100px);
  object-fit: contain;
}

.modal-content h3 {
  margin: 1rem 0;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #333;
}

@media (max-width: 768px) {
  .product-detail {
    padding: 1rem;
  }

  .cart-section {
    position: sticky;
    bottom: 0;
    margin: 1rem -1rem -1rem;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
}

.instructions {
  margin-bottom: 1rem;
  color: #666;
  font-size: 0.9rem;
}

.selected-indicator {
  font-size: 0.8rem;
  color: #4a90e2;
  font-weight: 500;
}

.special-message {
  margin: 1rem 0 1.5rem;
  padding: 1rem;
  background: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  color: #856404;
}

.special-message-title {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
  color: #856404;
}

.special-message-text {
  margin: 0;
  white-space: pre-line;
}

.product-header.sold-out .toggle-icon {
  display: none;
}

.product-photos-container {
  position: relative;
  padding: 1rem;
  background: white;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.photos-slider {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  padding: 0.5rem 0;
}

.photos-slider::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.photo-slide {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-slide:hover {
  transform: scale(1.05);
}

.photo-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.2rem;
  z-index: 1;
  transition: all 0.2s;
}

.nav-button:hover:not(:disabled) {
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.nav-button.prev {
  left: 0.5rem;
}

.nav-button.next {
  right: 0.5rem;
}

@media (max-width: 768px) {
  .photo-slide {
    width: 150px;
    height: 150px;
  }
  
  .nav-button {
    width: 32px;
    height: 32px;
    font-size: 1rem;
  }
}

.modal-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 2;
  transition: all 0.2s;
}

.modal-nav:hover:not(:disabled) {
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modal-nav:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.modal-nav.prev {
  left: 1rem;
}

.modal-nav.next {
  right: 1rem;
}

@media (max-width: 768px) {
  .modal-nav {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }
}

/* Moved to SocialSharing.vue component 
.social-sharing {
  margin: 1.5rem 0;
  padding: 0 1rem;
}

.social-sharing h3 {
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
  font-weight: 500;
}

.share-buttons {
  display: flex;
  gap: 1rem;
}

.share-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  transition: all 0.2s;
  text-decoration: none;
  font-size: 1.2rem;
}

.share-btn:hover {
  transform: translateY(-3px);
}

.share-btn.facebook {
  background-color: #3b5998;
}

.share-btn.twitter {
  background-color: #1da1f2;
}

.share-btn.pinterest {
  background-color: #e60023;
}

.share-btn.nextdoor {
  background-color: #00B53A;
}

.share-btn.email {
  background-color: #657786;
}

.share-btn.copy {
  background-color: #4a90e2;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .share-buttons {
    gap: 0.5rem;
  }
  
  .share-btn {
    width: 36px;
    height: 36px;
    font-size: 1rem;
  }
  
  .social-sharing {
    margin: 1rem 0;
    padding: 0;
  }
}
*/
</style> 