import { CartProduct } from "@/models/CartItem";

// Represents an image path with dimensions (reused from PrintV2)
export class ImagePath {
    constructor(url, width, height) {
        this.url = url;
        this.width = width;
        this.height = height;
    }

    static fromApiResponse(api) {
        if (!api?.url || !api?.width || !api?.height) {
            throw new Error('ImagePath requires url, width, and height');
        }
        return new ImagePath(api.url, api.width, api.height);
    }
}

// Represents a photo in a product
export class ProductPhoto {
    constructor(id, title, imagePathSmall, imagePathLarge) {
        this.id = id;
        this.title = title;
        this.imagePathSmall = imagePathSmall;
        this.imagePathLarge = imagePathLarge;
    }

    static fromApiResponse(api) {
        if (!api?.id || !api?.title || !api?.imagePathSmall) {
            throw new Error('ProductPhoto missing required fields');
        }

        return new ProductPhoto(
            api.id,
            api.title,
            ImagePath.fromApiResponse(api.imagePathSmall),
            api.imagePathLarge ? ImagePath.fromApiResponse(api.imagePathLarge) : null
        );
    }
}

// Represents a product in the V2 system
export class ProductV2 {
    constructor(productId, priceId, name, selectOption, imageUrl, unitPrice, features, specialMessage, inventory, type, weight, length, width, depth) {
        this.productId = productId;
        this.priceId = priceId;
        this.name = name;
        this.selectOption = selectOption;
        this.imageUrl = imageUrl;
        this.unitPrice = unitPrice;
        this.features = features || [];
        this.specialMessage = specialMessage;
        this.inventory = inventory == null ? -1 : inventory;
        this.selectOptions = [];
        this.type = type;
        this.weight = weight
        this.length = length
        this.width = width
        this.depth = depth
    }

    static fromApiResponse(type, api) {
        if (!api?.productId || !api?.priceId || !api?.name || !api?.selectOption) {
            throw new Error('Product missing required fields');
        }

        const product = new ProductV2(
            api.productId,
            api.priceId,
            api.name,
            api.selectOption,
            api.imageUrl,
            api.unitPrice,
            api.features,
            api.specialMessage,
            api.inventory,
            type,
            api.weight,
            api.length,
            api.width,
            api.depth
        );
        product.selectOptions = [{
            value: api.selectOption,
            label: api.selectOption,
            price: api.unitPrice,
            recommended: false
        }];
        return product;
    }

    toCartProduct(productUrl) {
        return new CartProduct(
            this.productId,
            this.priceId,
            this.name,
            this.unitPrice,
            this.imageUrl,
            productUrl,
            'Each',
            this.selectOption,
            this.inventory,
            this.features,
            this.type,
            this.weight,
            this.length,
            this.width,
            this.depth,
        );
    }
}

// Represents a product group in the V2 system
export class ProductGroupV2 {
    constructor(type, group, groupDescription, groupImageUrl, selectName, products, photos, images) {
        this.type = type;
        this.group = group;
        this.groupDescription = groupDescription;
        this.groupImageUrl = groupImageUrl;
        this.selectName = selectName;
        this.products = products || [];
        this.photos = photos || [];
        this.images = images || [];
    }

    static fromApiResponse(api) {
        if (!api?.type || !api?.group) {
            throw new Error('ProductGroup missing required fields');
        }
        return new ProductGroupV2(
            api.type,
            api.group,
            api.groupDescription,
            api.groupImageUrl,
            api.selectName,
            (api.products || []).map(p => ProductV2.fromApiResponse(api.type, p)),
            api.photos || [],
            api.images || []
        );
    }
}

// Represents a product type in the V2 system
export class ProductTypeV2 {
    constructor(type, description, imageUrl, productGroups) {
        this.type = type;
        this.description = description;
        this.imageUrl = imageUrl;
        this.productGroups = productGroups || [];
    }

    static fromApiResponse(api) {
        if (!api?.type) {
            throw new Error('ProductType missing required fields');
        }
        return new ProductTypeV2(
            api.type,
            api.description,
            api.imageUrl,
            (api.productGroups || []).map(g => ProductGroupV2.fromApiResponse(g))
        );
    }
} 