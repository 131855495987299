<template>
  <div class="container">
  <div class="prints-page">
    <!-- Loading State -->
    <loading-modal v-if="isLoading" />


    <!-- Error State -->
    <div v-if="error" class="error-alert">
      {{ error }}
      <button @click="retryLoading">Retry</button>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <!-- Header -->
      <div class="page-header" v-if="!currentCategory">
        <h1>{{ pageTitle }}</h1>
      </div>

      <!-- Categories Grid -->
      <div v-if="!currentCategory" class="categories-grid">
        <div
          v-for="category in categories"
          :key="category.category"
          class="category-card"
          @click="navigateToCategory(category)"
        >
          <div class="image-container">
            <img 
              :src="getCategoryImage(category)" 
              :alt="category.category"
            />
            <div class="overlay">
              <h3>{{ formatCategoryName(category.category) }}</h3>
<!--              <p>{{ category.description }}</p>-->
            </div>
          </div>
        </div>
      </div>

      <!-- Category View -->
      <router-view 
        v-else 
        :category="currentCategory"
      ></router-view>
    </div>

    </div>
  </div>
</template>

<script>
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import LoadingModal from '@/components/LoadingModal.vue'
import errorReporting from "@/errorReporting";

export default {
  name: 'PrintsV2',
  components: {
    LoadingModal
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    // Computed Properties
    const isLoading = computed(() => store.state.printsv2.loading)
    const error = computed(() => store.state.printsv2.error)
    const categories = computed(() => store.state.printsv2.categories)
    const currentCategory = computed(() => {
      if (!route.params.category) return null
      return categories.value?.find(c => c.category === route.params.category)
    })
    const currentPhotos = computed(() => {
      if (!currentCategory.value) return []
      return currentCategory.value.photos
    })
    const pageTitle = computed(() => 
      currentCategory.value ? currentCategory.value.title || currentCategory.value.category : 'Print Collections'
    )

    // Methods
    const loadCategories = async () => {
      try {
        await store.dispatch('printsv2/loadCategories')
      } catch (error) {
        console.error('Failed to load categories:', error)
        errorReporting.reportVueError(error, this, 'PrintV2.loadCategories')
      }
    }

    const navigateToCategory = (category) => {
      router.push({
        name: 'prints-v2-category',
        params: { category: category.category }
      })
    }

    const navigateToPhoto = (photo) => {
      if (!route.params.category) return;
      router.push({
        name: 'prints-v2-photo',
        params: {
          category: route.params.category,
          photoId: photo.id
        }
      })
    }

    const retryLoading = () => {
      loadCategories()
    }

    // Helper Methods
    const formatCategoryName = (category) => {
      return category
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    const getCategoryImage = (category) => {
      // Use the first photo's small image as the category cover
      if (category.photos && category.photos.length > 0) {
        return category.photos[0].imagePathSmall.url;
      }
      // Return a placeholder if no photos
      return '/images/placeholder.jpg';
    }

    // Lifecycle Hooks
    onMounted(() => {
      loadCategories()
    })

    // Route Watcher
    watch(
      () => route.params.category,
      (newCategory) => {
        if (newCategory && !currentCategory.value) {
          loadCategories()
        }
      }
    )

    return {
      isLoading,
      error,
      categories,
      currentCategory,
      currentPhotos,
      pageTitle,
      navigateToCategory,
      navigateToPhoto,
      retryLoading,
      formatCategoryName,
      getCategoryImage
    }
  }
}
</script>

<style scoped>
.prints-page {
  /*padding: 2rem;*/
  max-width: 1400px;
  margin: 0 auto;
}

.page-header {
  margin-bottom: 2rem;
  text-align: center;
}

/*.breadcrumb {
  margin-top: 1rem;
}*/

/*.breadcrumb a {
  color: #4a90e2;
  text-decoration: none;
}*/

.error-alert {
  background-color: #ffebee;
  color: #c62828;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-alert button {
  background-color: #c62828;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.categories-grid,
.photos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.category-card,
.photo-card {
  cursor: pointer;
  transition: transform 0.2s;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.category-card:hover,
.photo-card:hover {
  transform: translateY(-4px);
}

.image-container {
  position: relative;
  padding-top: 75%;
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(transparent, rgba(0, 0, 0, 2.5));
  padding: 1rem;
  color: white;
}

.overlay h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.5rem;
  color: white;
}

.overlay p {
  margin: 0;
  font-size: 0.875rem;
  opacity: 0.9;
  color: white;
}

/*.recommended-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #4caf50;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: bold;
}*/

@media (max-width: 768px) {
  .prints-page {
    padding: 0;
  }

  .categories-grid,
  .photos-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }
}
</style> 