<template>
  <nav class="navbar navbar-expand-md py-2 fixed-top">
    <div class="container">
      <router-link to="/" class="navbar-brand d-flex align-items-center">
        <span>
          MossBeachLife.com
        </span>
      </router-link>

      <button @click="visible = !visible"
              class="navbar-toggler"
              type="button"
              :class="visible ? '' : 'collapsed'"
              :aria-expanded="visible ? 'true' : 'false'">
        <span class="visually-hidden">Toggle navigation</span>
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="navbarNav"
           class="collapse navbar-collapse"
           :class="visible ? 'show' : ''">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link :to="{ name: 'home' }"
                         class="nav-link"
                         :class="{active: $route.name === 'home'}"
                         @click="visible = false">
              Home
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'products-v2' }"
                         class="nav-link"
                         :class="{active: $route.name === 'products-v2' }"
                         @click="visible = false">
              Products
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'prints-v2' }"
                         class="nav-link"
                         :class="{active: $route.name === 'prints-v2' }"
                         @click="visible = false">
              Prints
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'gallery' }"
                         class="nav-link"
                         :class="{active: $route.name === 'gallery'}"
                         @click="visible = false">
              Gallery
            </router-link>
          </li>

          <!-- Only display when cart icon is NOT shown: d-md-none d-block -->
          <li class="nav-item d-md-none d-block" >
            <router-link :to="{ name: 'cart' }"
                         class="nav-link"
                         :class="{active: $route.name === 'cart'}"
                         @click="visible = false">
              Cart
            </router-link>
          </li>
        </ul>

        <!-- Only display when cart menu is NOT shown: d-none d-md-block-->
        <div class="d-none d-md-block ms-4">
          <router-link :to="{ name: 'cart' }"
                       class="nav-link"
                       :class="{active: $route.name === 'cart'}">
            <div v-if="cartItems">
              <cart-heart-icon :size="25" fill-color="red"/>
            </div>
            <div v-else>
              <cart-outline-icon :size="25"/>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import CartOutlineIcon from 'vue-material-design-icons/CartOutline.vue';
import CartHeartIcon from 'vue-material-design-icons/CartHeart.vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

// https://github.com/robcresswell/vue-material-design-icons
// https://pictogrammers.com/library/mdi/
export default {
  components: {
    CartOutlineIcon,
    CartHeartIcon
  },
  setup() {
    const store = useStore();
    const visible = ref(false);
    
    // Get cart items from store
    const cartItems = computed(() => {
      const cart = store.getters["cart/cart"];
      return cart && cart.length > 0;
    });
    
    // Get enhanced mode state from store
    const isEnhancedMode = computed(() => store.getters["metadata/isEnhancedMode"]);

    return {
      cartItems,
      isEnhancedMode,

      // Additional UI state
      enableContact: computed(() => isEnhancedMode.value),
      enableAbout: computed(() => isEnhancedMode.value),
      visible
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    handleClickOutside(event) {
      const navbar = document.getElementById('navbarNav')
      const togglerButton = document.querySelector('.navbar-toggler')

      if (this.visible &&
          !navbar.contains(event.target) &&
          !togglerButton.contains(event.target)) {
        this.visible = false
      }
    }
  }
}
</script>
<style>
.navbar {
  background-color: var(--bs-body-bg);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}

.navbar-brand {
  transition: all 0.2s ease-in-out;
}

.navbar-brand:hover {
  transform: scale(1.05);
  text-decoration: underline !important;
}

.nav-link {
  position: relative;
  transition: all 0.2s ease-in-out;
}

.nav-link:hover {
  transform: scale(1.10);
  text-decoration: underline !important;
}

.nav-link.active {
  color: var(--bs-link-selected) !important; /* Golden yellow */
  font-weight: 500 !important;
  text-decoration: underline !important;
}

.nav-link.active:hover {
  transform: scale(1.00);
  color: var(--bs-link-selected) !important; /* Maintains the yellow active state */
}

.external-link .nav-link:hover {
  color: var(--bs-external-color) !important;
}

.nav-link:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--bs-body-bg);
  transition: width 0.2s ease;
}

@media (max-width: 768px) {
  .navbar-collapse {
    background-color: var(--bs-body-bg);
    padding: 0.5rem;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1020;
    display: none;
  }

  .navbar-collapse.show {
    display: block !important;
  }
  
  .navbar-nav {
    margin: 0;
    padding: 0;
  }
  
  .nav-item {
    margin: 0.25rem 0;
  }

  .navbar-toggler {
    border: none;
    padding: 0.5rem;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.75)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
}
</style>