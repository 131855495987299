/**
 * See https://css-tricks.com/lazy-loading-images-with-vue-js-directives-and-intersection-observer/
 *
 * I used AmazonQ to help set up working directive
 */

export default {
    mounted(el) {
        function loadImage() {
            const imageElement = el.tagName === 'IMG' ? el : el.querySelector('img');

            if (imageElement) {
                const imageUrl = imageElement.getAttribute('data-url');
                if (imageUrl) {
                    imageElement.src = imageUrl;
                }
            }
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadImage();
                    observer.unobserve(el);
                }
            });
        });

        observer.observe(el);
    }
}